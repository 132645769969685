import { ExtrusionDto, SteelDto } from 'src/lib/api';

export const SpanSize = {
  SINGLE: 'single',
  TWIN: 'twin'
};
export interface MemberIndex {
  index: number;
  value: any;
}

export interface IGeneralProps {
  spanSize: string;
  windLoad: number | string;
  jambCaulkJointWidth: number | string;
  overrideDeflection: boolean;
  spanHeightFt: number | string;
  spanHeightIn: number | string;
  spacingFt: number | string;
  spacingIn: number | string;
  midspanHeightFt: number | string;
  midspanHeightIn: number | string;
  lowCriteriaL: number | string;
  lowCriteriaAdd: number | string;
  upCriteriaL: number | string;
  upCriteriaAdd: number | string;
  spacingError: boolean;
  spanlowError: boolean;
}
export interface IMemberResult {
  stress: number;
  stressPass: boolean;
  deflectionLow: number;
  deflectionLowPass: boolean;
  deflectionUp: number;
  deflectionUpPass: boolean;
  reactionHead: number;
  reactionAnchor: number;
  reactionSill: number;
  requiredIx: number;
  requiredSx: number;
  needsSteelOptimization: boolean;
  requiredSteelIx: number;
  spanHeightError: boolean;
  variables: any;
  sx: number;
  ix: number;
}
export interface IMemberStack extends IMemberResult {
  index: number;
  filteredMembers: Array<ExtrusionDto>;
  selectedMemberType: string;
  selectedMember: ExtrusionDto;
  filteredSteel: Array<SteelDto>;
  selectedSteel: SteelDto;
}

export class General implements IGeneralProps {
  spanSize: string;
  windLoad: number;
  jambCaulkJointWidth: number;
  overrideDeflection: boolean;
  spanHeightFt: number;
  spanHeightIn: number;
  spacingFt: number;
  spacingIn: number;
  midspanHeightFt: number;
  midspanHeightIn: number;
  lowCriteriaL: number;
  lowCriteriaAdd: number;
  upCriteriaL: number;
  upCriteriaAdd: number;

  constructor(generalProps: IGeneralProps) {
    this.spanSize = generalProps.spanSize;
    this.windLoad = Number(generalProps.windLoad);
    this.jambCaulkJointWidth = Number(generalProps.jambCaulkJointWidth);
    this.overrideDeflection = generalProps.overrideDeflection;
    this.spanHeightFt = Number(generalProps.spanHeightFt);
    this.spanHeightIn = Number(generalProps.spanHeightIn);
    this.spacingFt = Number(generalProps.spacingFt);
    this.spacingIn = Number(generalProps.spacingIn);
    this.midspanHeightFt = Number(generalProps.midspanHeightFt);
    this.midspanHeightIn = Number(generalProps.midspanHeightIn);
    this.lowCriteriaL = Number(generalProps.lowCriteriaL);
    this.lowCriteriaAdd = Number(generalProps.lowCriteriaAdd);
    this.upCriteriaL = Number(generalProps.upCriteriaL);
    this.upCriteriaAdd = Number(generalProps.upCriteriaAdd);
  }
  get spacing(): number {
    return this.spacingFt * 12 + this.spacingIn;
  }
  get span(): number {
    return this.spanHeightFt * 12 + this.spanHeightIn;
  }
  get spanLow(): number {
    return this.midspanHeightFt * 12 + this.midspanHeightIn;
  }
  get spanUp(): number {
    return this.span > 0 && this.spanLow > 0 ? this.span - this.spanLow : 0;
  }
  get WindloadPsi(): number {
    return Math.abs(this.windLoad) / 144;
  }

  get isValid(): boolean {
    const singleReq =
      Math.abs(this.windLoad) > 0 &&
      this.jambCaulkJointWidth > 0 &&
      this.span > 0 &&
      this.spacing > 0 &&
      this.span >= this.spacing &&
      (!this.overrideDeflection ||
        (this.overrideDeflection &&
          (this.lowCriteriaAdd > 0 || this.lowCriteriaL > 0)));
    const twinReq =
      this.spanSize === SpanSize.TWIN
        ? this.spanLow > 0 &&
          (!this.overrideDeflection ||
            (this.overrideDeflection &&
              (this.upCriteriaAdd > 0 || this.upCriteriaL > 0)))
        : true;
    return singleReq && twinReq;
  }

  get spacingError(): boolean {
    return this.span > 0 && this.spacing > 0 && this.spacing > this.span;
  }

  get spanlowError(): boolean {
    return this.span > 0 && this.spanLow > 0 && this.spanLow >= this.span;
  }
}
export class MemberStack {
  E: number;
  index: number;
  centerlineWidth: number;
  centerlineWidth2: number;
  filteredMembers: ExtrusionDto[];
  selectedMember: ExtrusionDto;
  filteredSteel: SteelDto[];
  selectedSteel: SteelDto;
  general: General;

  constructor(
    general: General,
    width1: number | string,
    width2: number | string,
    member: ExtrusionDto,
    steel: SteelDto
  ) {
    this.E = 10100000; //Aluminum modulus
    this.general = general;
    this.centerlineWidth = Number(width1);
    this.centerlineWidth2 = Number(width2);
    this.selectedMember = member;
    this.selectedSteel = steel;
  }

  get Ix(): number {
    return this.selectedMember.ix + 2.9 * this.selectedSteel.ixx;
  }

  get Sx(): number {
    return this.selectedSteel
      ? (this.Ix * this.selectedMember.sx) / this.selectedMember.ix
      : this.selectedMember.sx;
  }

  get isJamb(): boolean {
    return this.centerlineWidth === 0 || this.centerlineWidth2 === 0;
  }

  get windloadLbIn(): number {
    return (
      this.general.WindloadPsi *
      (this.centerlineWidth + this.centerlineWidth2) *
      0.5
    );
  }

  get stressBackmember(): number {
    //Stress limit of material and backmember shape
    //Constants
    var orupture: number,
      oflexure: number,
      fb: number,
      ftu: number,
      fcy: number,
      fty: number,
      kt: number;
    orupture = 1.95;
    oflexure = 1.65;
    fb = 15200; //psi
    ftu = 30000; //psi
    fcy = 25000; //psi
    fty = 25000; //psi
    kt = 1;

    //Yielding & Rupture
    var myield: number, mrupture: number, m1: number, m2: number, m3: number;
    //Flexural Yield Strength Limit
    m1 = this.selectedMember.zx * fcy;
    m2 = 1.5 * this.selectedMember.sx * fty;
    m3 = 1.5 * this.selectedMember.sx * fcy;
    myield = Math.min(Math.min(m1, m2), m3) / oflexure; //Max Moment - Yield in*lb
    //Flexural Rupture Strength Limit
    mrupture = (this.selectedMember.zx * ftu) / kt / orupture; //Max Moment - Rupture in*lb

    //Backmember Limits
    var mall: number, stressADM: number;
    mall = Math.min(myield, mrupture);
    stressADM = mall / this.selectedMember.sx;
    return Math.min(fb, stressADM); //max allowable stress, psi
  }

  get stressBuckling(): number {
    //Buckling limit based on DLO size

    //Constants
    var oflexure: number,
      fcy: number,
      cb: number,
      c1: number,
      c2: number,
      k: number,
      go: number;
    oflexure = 1.65;
    fcy = 25000; //psi
    cb = 1;
    c1 = 0.5;
    c2 = 0.5;
    go = 0.0; //in
    k = 1.0; //psi

    //Lateral Torsional Buckling
    var U: number,
      me: number,
      lambda: number,
      bc: number,
      dc: number,
      cc: number,
      mb: number;
    U = c1 * go + (c2 * this.selectedMember.b) / 2;
    me =
      ((cb * Math.pow(Math.PI, 2) * this.E * this.selectedMember.iy) /
        Math.pow(this.general.spacing, 2)) *
      (U +
        Math.sqrt(
          Math.pow(U, 2) +
            (0.038 *
              this.selectedMember.j *
              Math.pow(this.general.spacing, 2)) /
              this.selectedMember.iy +
            this.selectedMember.cw / this.selectedMember.iy
        ));
    lambda = Math.PI * Math.sqrt((this.E * this.selectedMember.sx) / me);
    //Member Buckling
    bc = fcy * (1 + Math.sqrt(fcy / (2250 * k)));
    dc = (bc / 10) * Math.sqrt(bc / this.E);
    cc = 0.41 * (bc / dc);
    //Lateral Torsional Buckling
    if (lambda < cc) {
      //Max Moment - Buckling in*lb
      mb =
        (me * (1 - lambda / cc) +
          (Math.pow(Math.PI, 2) * this.E * lambda * this.selectedMember.sx) /
            Math.pow(cc, 3)) /
        oflexure;
    } else {
      mb =
        (Math.pow(Math.PI, 2) * this.E * this.selectedMember.sx) /
        Math.pow(lambda, 2) /
        oflexure;
    }

    return mb / this.selectedMember.sx;
  }

  get stresslimit(): number {
    return Math.min(this.stressBackmember, this.stressBuckling);
  }

  clearResults(): IMemberResult {
    return {
      stress: null,
      stressPass: null,
      deflectionLow: null,
      deflectionLowPass: null,
      deflectionUp: null,
      deflectionUpPass: null,
      reactionHead: null,
      reactionAnchor: null,
      reactionSill: null,
      requiredIx: null,
      requiredSx: null,
      needsSteelOptimization: false,
      requiredSteelIx: null,
      spanHeightError: null,
      variables: null,
      sx: null,
      ix: null
    };
  }

  calculateSpan(): IMemberResult {
    var result = this.clearResults();
    var deflectionlimitLow: number, deflectionlimitUp: number;
    var variables = {} as any;

    if (this.general.spanSize === SpanSize.SINGLE) {
      //SINGLE SPAN

      if (this.general.span > this.selectedMember.maxSpan * 12) {
        result.spanHeightError = true;
        return result;
      }

      if (this.isJamb) {
        //Dow Construction Sealants Technical Manual: Max shear movement = (1.5^2 + 1^2)^(1/2) = 1.118 * caulk joint width
        deflectionlimitLow = this.general.jambCaulkJointWidth * 1.118;
      } else {
        deflectionlimitLow =
          this.general.span / this.general.lowCriteriaL +
          this.general.lowCriteriaAdd;
      }

      var deflection: number,
        moment: number,
        stress: number,
        endReactions: number,
        reqIx: number,
        reqSx: number,
        ixSteelDeflection: number,
        ixSteelStress: number;

      moment = (this.windloadLbIn * Math.pow(this.general.span, 2)) / 8;
      deflection =
        (5 * this.windloadLbIn * Math.pow(this.general.span, 4)) /
        (384 * this.E * this.Ix);
      stress = moment / this.Sx;
      endReactions = this.windloadLbIn * this.general.span * 0.5;
      reqIx =
        (5 * this.windloadLbIn * Math.pow(this.general.span, 4)) /
        (deflectionlimitLow * 384 * this.E);
      reqSx = moment / this.stresslimit;
      ixSteelDeflection = (reqIx - this.selectedMember.ix) / 2.9;
      ixSteelStress =
        ((reqSx * this.selectedMember.ix) / this.selectedMember.sx -
          this.selectedMember.ix) /
        2.9;

      variables.span = this.general.span;
      variables.isJamb = this.isJamb;
      variables.jambCaulkJointWidth = this.general.jambCaulkJointWidth;
      variables.deflectionlimitLow = deflectionlimitLow;
      variables.moment = moment;
      variables.deflection = deflection;
      variables.stress = stress;
      variables.stresslimit = this.stresslimit;
      variables.endReactions = endReactions;
      variables.reqIx = reqIx;
      variables.reqSx = reqSx;
      variables.ixSteelDeflection = ixSteelDeflection;
      variables.ixSteelStress = ixSteelStress;
      variables.windloadLbIn = this.windloadLbIn;
      variables.Ix = this.Ix;
      variables.Sx = this.Sx;
      variables.selectedMemberix = this.selectedMember.ix;
      variables.selectedSteelixx = this.selectedSteel.ixx;
      variables.lowCriteriaL = this.general.lowCriteriaL;
      variables.lowCriteriaAdd = this.general.lowCriteriaAdd;
      variables.needsSteelOptimization =
        stress < this.stresslimit || deflection < deflectionlimitLow;
      variables.requiredSteelIx = Math.max(ixSteelDeflection, ixSteelStress);
      variables.selectedMember = this.selectedMember;

      result.stress = stress;
      result.stressPass = stress < this.stresslimit;
      result.deflectionLow = deflection;
      result.deflectionLowPass = deflection < deflectionlimitLow;
      result.reactionHead = endReactions;
      result.reactionSill = endReactions;
      result.requiredIx = reqIx;
      result.requiredSx = reqSx;
      result.needsSteelOptimization =
        stress < this.stresslimit || deflection < deflectionlimitLow;
      result.requiredSteelIx = Math.max(ixSteelDeflection, ixSteelStress);
    } else {
      //TWIN SPAN//////////////////////////////////////////////////////////
      if (this.general.span > this.selectedMember.maxSpan * 24) {
        result.spanHeightError = true;
        return result;
      }

      if (this.isJamb) {
        //Dow Construction Sealants Technical Manual: Max shear movement = (1.5^2 + 1^2)^(1/2) = 1.118 * caulk joint width
        deflectionlimitLow = this.general.jambCaulkJointWidth * 1.118;
        deflectionlimitUp = deflectionlimitLow;
      } else {
        deflectionlimitLow =
          this.general.spanLow / this.general.lowCriteriaL +
          this.general.lowCriteriaAdd;
        deflectionlimitUp =
          this.general.spanUp / this.general.upCriteriaL +
          this.general.upCriteriaAdd;
      }

      const deflectionLow1Arr = this.twinDeflection(
        this.general.spanLow,
        false
      );
      const deflectionLow2Arr = this.twinDeflection(this.general.spanLow, true);
      const deflectionUp1Arr = this.twinDeflection(this.general.spanUp, false);
      const deflectionUp2Arr = this.twinDeflection(this.general.spanUp, true);

      const anchorMoment =
        (-this.windloadLbIn *
          0.125 *
          (Math.pow(this.general.spanUp, 3) +
            Math.pow(this.general.spanLow, 3))) /
        (this.general.spanUp + this.general.spanLow);

      const endReactionLow =
        this.windloadLbIn * this.general.spanLow * 0.5 +
        anchorMoment / this.general.spanLow;
      const endReactionUp =
        this.windloadLbIn * this.general.spanUp * 0.5 +
        anchorMoment / this.general.spanUp;
      const endReactionAnchor =
        this.windloadLbIn * this.general.span - endReactionUp - endReactionLow;

      const stressLow = this.twinStress(anchorMoment, endReactionLow);
      const stressUp = this.twinStress(anchorMoment, endReactionUp);
      const stressMax = Math.max(stressLow, stressUp);

      const deflectionLowArr =
        deflectionLow1Arr[0] >= deflectionLow2Arr[0]
          ? deflectionLow1Arr
          : deflectionLow2Arr;
      const deflectionUpArr =
        deflectionUp1Arr[0] >= deflectionUp2Arr[0]
          ? deflectionUp1Arr
          : deflectionUp2Arr;

      // const reqIxLow = ((this.windloadLbIn * deflectionLowArr[1]) / (48 * deflectionlimitLow * this.E)) *
      //                 (2 * Math.pow(deflectionLowArr[1], 3) - (Math.pow(deflectionLowArr[1], 2) / this.general.spanLow) *
      //                 (Math.pow(this.general.spanLow, 2) + 3 * this.general.span * this.general.spanLow - Math.pow(this.general.span, 2)) - this.general.spanLow *
      //                 (Math.pow(this.general.spanLow, 2) - 3 * this.general.span * this.general.spanLow + Math.pow(this.general.span, 2)));
      const reqIxLow =
        ((this.windloadLbIn * deflectionLowArr[1]) /
          (48 * deflectionlimitLow * this.E)) *
        (2 * Math.pow(deflectionLowArr[1], 3) -
          (Math.pow(deflectionLowArr[1], 2) / this.general.spanLow) *
            (Math.pow(this.general.spanLow, 2) +
              3 * this.general.span * this.general.spanLow -
              Math.pow(this.general.span, 2)) -
          this.general.spanLow *
            (Math.pow(this.general.spanLow, 2) -
              3 * this.general.span * this.general.spanLow +
              Math.pow(this.general.span, 2)));

      const reqIxUp =
        ((this.windloadLbIn * deflectionUpArr[1]) /
          (48 * deflectionlimitUp * this.E)) *
        (2 * Math.pow(deflectionUpArr[1], 3) -
          (Math.pow(deflectionUpArr[1], 2) / this.general.spanUp) *
            (Math.pow(this.general.spanUp, 2) +
              3 * this.general.span * this.general.spanUp -
              Math.pow(this.general.span, 2)) -
          this.general.spanUp *
            (Math.pow(this.general.spanUp, 2) -
              3 * this.general.span * this.general.spanUp +
              Math.pow(this.general.span, 2)));

      const reqIx = Math.max(Math.abs(reqIxLow), Math.abs(reqIxUp));
      const reqSx = (stressMax * this.Sx) / this.stresslimit;
      const ixSteelDeflection = (reqIx - this.selectedMember.ix) / 2.9;
      const ixSteelStress =
        ((reqSx * this.selectedMember.ix) / this.selectedMember.sx -
          this.selectedMember.ix) /
        2.9;
      /////////////////////////////////////

      variables.windloadLbIn = this.windloadLbIn;
      variables.deflectionLowArr1 = deflectionLowArr[1];
      variables.deflectionlimitLow = deflectionlimitLow;
      variables.E = this.E;
      variables.spanLow = this.general.spanLow;
      variables.span = this.general.span;
      // variables.span = this.general.span;
      // variables.isJamb = this.isJamb;
      // variables.jambCaulkJointWidth = this.general.jambCaulkJointWidth;
      // variables.deflectionlimitLow = deflectionlimitLow;
      // variables.deflectionlimitUp = deflectionlimitUp;
      // variables.deflectionLow1Arr = deflectionLow1Arr;
      // variables.deflectionLow2Arr = deflectionLow2Arr;
      // variables.deflectionUp1Arr = deflectionUp1Arr;
      // variables.deflectionUp2Arr = deflectionUp2Arr;
      // variables.anchorMoment = anchorMoment;
      // variables.endReactionLow = endReactionLow;
      // variables.endReactionUp = endReactionUp;
      // variables.endReactionAnchor = endReactionAnchor;
      // variables.stressLow = stressLow;
      // variables.stressUp = stressUp;
      // variables.stressMax = stressMax;
      // variables.deflectionLowArr = deflectionLowArr;
      // variables.deflectionUpArr = deflectionUpArr;
      // variables.reqIxLow = reqIxLow;
      // variables.reqIxUp = reqIxUp;
      // variables.stresslimit = this.stresslimit;
      // variables.reqIx = reqIx;
      // variables.reqSx = reqSx;
      // variables.ixSteelDeflection = ixSteelDeflection;
      // variables.ixSteelStress = ixSteelStress;
      // variables.windloadLbIn = this.windloadLbIn;
      // variables.Ix = this.Ix;
      // variables.Sx = this.Sx;
      // variables.selectedMemberix = this.selectedMember.ix;
      // variables.selectedSteelixx = this.selectedSteel.ixx;
      // variables.lowCriteriaL = this.general.lowCriteriaL;
      // variables.lowCriteriaAdd = this.general.lowCriteriaAdd;
      // variables.requiredSteelIx = Math.max(ixSteelDeflection, ixSteelStress);

      result.stress = stressMax;
      result.stressPass = stressMax < this.stresslimit;
      result.deflectionLow = deflectionLowArr[0];
      result.deflectionLowPass = deflectionLowArr[0] < deflectionlimitLow;
      result.deflectionUp = deflectionUpArr[0];
      result.deflectionUpPass = deflectionUpArr[0] < deflectionlimitUp;
      result.reactionHead = endReactionUp;
      result.reactionSill = endReactionLow;
      result.reactionAnchor = endReactionAnchor;
      result.requiredIx = reqIx;
      result.requiredSx = reqSx;
      result.needsSteelOptimization =
        !result.stressPass ||
        !result.deflectionLowPass ||
        !result.deflectionUpPass;
      result.requiredSteelIx = Math.max(ixSteelDeflection, ixSteelStress);

      // variables.needsSteelOptimization =
      //   !result.stressPass ||
      //   !result.deflectionLowPass ||
      //   !result.deflectionUpPass;
      // variables.selectedMember = this.selectedMember;
    }

    result.variables = variables;
    result.sx = this.Sx;
    result.ix = this.Ix;
    return result;
  }

  twinDeflection(upOrLowSpan: number, fromAnchor: boolean): number[] {
    var d1 = 0,
      x1 = 0,
      x11 = 0,
      x12 = 0,
      spanStart = 0,
      spanEnd = upOrLowSpan;
    var spanIncrement = spanEnd;

    var spanIncrementPercent,
      d = 0;
    if (fromAnchor) {
      //From midspan anchor
      while (spanIncrement > 0.1) {
        spanIncrementPercent = 0.05 * spanIncrement;
        d1 = 0;
        for (let x = spanEnd; x >= spanEnd - spanIncrement; ) {
          d =
            ((this.windloadLbIn * x) / (48 * this.E * this.Ix)) *
            (2 * Math.pow(x, 3) -
              (Math.pow(x, 2) / upOrLowSpan) *
                (Math.pow(upOrLowSpan, 2) +
                  3 * this.general.span * upOrLowSpan -
                  Math.pow(this.general.span, 2)) -
              upOrLowSpan *
                (Math.pow(upOrLowSpan, 2) -
                  3 * this.general.span * upOrLowSpan +
                  Math.pow(this.general.span, 2)));
          if (Math.abs(d1) > Math.abs(d)) {
            x12 = x;
            break;
          } else {
            d1 = d;
            x1 = x;
            x11 = x + spanIncrementPercent;
          }

          x = x - spanIncrementPercent;
        }

        spanEnd = x11;
        spanIncrement = x11 - x12;
      }
    } else {
      //From ends of spans
      while (spanIncrement > 0.1) {
        spanIncrementPercent = 0.05 * spanIncrement;
        d1 = 0;

        for (let x = spanStart; x <= spanIncrement + spanStart; ) {
          d =
            ((this.windloadLbIn * x) / (48 * this.E * this.Ix)) *
            (2 * Math.pow(x, 3) -
              (Math.pow(x, 2) / upOrLowSpan) *
                (Math.pow(upOrLowSpan, 2) +
                  3 * this.general.span * upOrLowSpan -
                  Math.pow(this.general.span, 2)) -
              upOrLowSpan *
                (Math.pow(upOrLowSpan, 2) -
                  3 * this.general.span * upOrLowSpan +
                  Math.pow(this.general.span, 2)));
          if (Math.abs(d1) > Math.abs(d)) {
            x12 = x;
            break;
          } else {
            d1 = d;
            x1 = x;
            x11 = x - spanIncrementPercent;
          }

          x = x + spanIncrementPercent;
        }

        spanStart = x11;
        spanIncrement = x12 - x11;
      }
    }
    return [Math.abs(d1), x1];
  }

  twinStress(anchorMoment: number, endReactionUp: number): number {
    //TODO: x is assigned, but never used
    // var x = 0;
    var momentPositive = 0;
    if (endReactionUp > 0) {
      // x = endReactionUp / this.windloadLbIn;
      momentPositive = Math.pow(endReactionUp, 2) / (2 * this.windloadLbIn);
    }
    var moment = Math.max(momentPositive, Math.abs(anchorMoment));
    return moment / this.Sx;
  }
}
