import React, { useEffect } from 'react';
import type { FC, ReactNode } from 'react';
import PropTypes from 'prop-types';
import useAuth from '../hooks/useAuth';

interface AuthGuardProps {
  children?: ReactNode;
}

const AuthGuard: FC<AuthGuardProps> = ({ children }) => {
  const { loading, isAuthenticated, loginWithRedirect } = useAuth();
  useEffect(() => {
    if (loading || isAuthenticated) {
      return;
    }
    const fn = async () => {
      await loginWithRedirect();
    };
    fn();
  }, [loading, isAuthenticated, loginWithRedirect]);

  return isAuthenticated ? <>{children}</> : null;
};

AuthGuard.propTypes = {
  children: PropTypes.node
};

export default AuthGuard;
