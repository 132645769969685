import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { AppThunk } from 'src/store';
import axios from 'src/utils/axios';
import objFromArray from 'src/utils/objFromArray';
import type { Mail, Label } from 'src/types/mail';
import { UpdateUserMessageCommand } from 'src/lib/api';

interface MailState {
  mails: {
    byId: Record<string, Mail>;
    allIds: string[];
  };
  labels: Label[];
  isSidebarOpen: boolean;
  isComposeOpen: boolean;
}

const initialState: MailState = {
  mails: {
    byId: {},
    allIds: []
  },
  labels: [],
  isSidebarOpen: false,
  isComposeOpen: false
};

const slice = createSlice({
  name: 'mail',
  initialState,
  reducers: {
    getLabels(state: MailState, action: PayloadAction<{ labels: Label[] }>) {
      const { labels } = action.payload;

      state.labels = labels;
    },
    getMails(state: MailState, action: PayloadAction<{ messages: Mail[] }>) {
      const { messages } = action.payload;
      state.mails.byId = objFromArray(messages);
      state.mails.allIds = Object.keys(state.mails.byId);
    },
    getMail(state: MailState, action: PayloadAction<Mail>): void {
      const mail = action.payload;
      if (mail) {
        state.mails.byId[mail.id] = mail;

        if (!state.mails.allIds.includes(mail.id)) {
          state.mails.allIds.push(mail.id);
        }
      }
    },
    deleteMails(
      state: MailState,
      action: PayloadAction<{ messageIds: Array<string> }>
    ) {
      const { messageIds } = action.payload;
      if (messageIds.length > 0) {
        state.mails.allIds = state.mails.allIds.filter(
          (id) => !messageIds.includes(id)
        );
        messageIds.forEach((id) => (state.mails.byId[id] = undefined));
      }
    },
    deleteMail(state: MailState, action: PayloadAction<string>) {
      const messageId = action.payload;
      if (messageId) {
        state.mails.allIds = state.mails.allIds.filter(
          (id) => messageId !== id
        );
        state.mails.byId[messageId] = undefined;
      }
    },
    openSidebar(state: MailState) {
      state.isSidebarOpen = true;
    },
    closeSidebar(state: MailState) {
      state.isSidebarOpen = false;
    },
    openCompose(state: MailState) {
      state.isComposeOpen = true;
    },
    closeCompose(state: MailState) {
      state.isComposeOpen = false;
    }
  }
});

export const { reducer } = slice;

export const getLabels = (): AppThunk => async (dispatch) => {
  const response = await axios.get<{ labels: Label[] }>('/api/mail/labels');
  dispatch(slice.actions.getLabels(response.data));
};

export const getMails = (getMessages): AppThunk => async (dispatch) => {
  const messages = await getMessages();

  dispatch(slice.actions.getMails({ messages }));
};

// export const getMails = (params: {}): AppThunk => async (dispatch) => {
//   const response = await axios.get<{ mails: Mail[]; }>('/api/mail/mails', {
//     params
//   });
//   dispatch(slice.actions.getMails(response.data));
// };

export const getMail = (getMessageById, mailId: string): AppThunk => async (
  dispatch
) => {
  const message = await getMessageById(mailId);

  dispatch(slice.actions.getMail(message));
};

interface UpdateMailApis {
  updateUserMessage: (message: UpdateUserMessageCommand) => any;
  getMessageById: (id: string) => any;
}

export const updateMail = (
  apis: UpdateMailApis,
  mailUpdate: UpdateUserMessageCommand
): AppThunk => async (dispatch): Promise<void> => {
  const { updateUserMessage, getMessageById } = apis;
  const updatedMail = await updateUserMessage(mailUpdate);
  if (updatedMail.messageId) {
    const mail = await getMessageById(updatedMail.messageId);
    if (mail) dispatch(slice.actions.getMail({ ...mail, ...updatedMail }));
  }
};

export const deleteMail = (
  deleteUserMessage: (id: string) => any,
  id: string
): AppThunk => async (dispatch): Promise<void> => {
  await deleteUserMessage(id);
  dispatch(slice.actions.deleteMail(id));
};

export const deleteMails = (
  deleteUserMessages: (ids: Array<string>) => any,
  ids: Array<string>
): AppThunk => async (dispatch): Promise<void> => {
  await deleteUserMessages(ids);
  dispatch(slice.actions.deleteMails({ messageIds: ids }));
};

export const openSidebar = (): AppThunk => async (dispatch) => {
  dispatch(slice.actions.openSidebar());
};

export const closeSidebar = (): AppThunk => async (dispatch) => {
  dispatch(slice.actions.closeSidebar());
};

export const openCompose = (): AppThunk => async (dispatch) => {
  dispatch(slice.actions.openCompose());
};

export const closeCompose = (): AppThunk => async (dispatch) => {
  dispatch(slice.actions.closeCompose());
};

export const sendMail = (mailId: string): AppThunk => async (dispatch) => {
  dispatch(slice.actions.closeCompose());
};

export default slice;
