import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import axios from 'axios';
import { FileSystemItem, ProductFilesVm } from 'src/lib/api';
import type { AppThunk } from 'src/store';

interface FileState {
  isSidebarOpen: boolean;
  productFiles: ProductFilesVm;
  selectedFiles: Array<FileSystemItem>;
  expanded: Array<string>;
  selected: Array<string>;
}

const initialState: FileState = {
  isSidebarOpen: false,
  productFiles: {
    files: {
      children: []
    }
  },
  selectedFiles: [],
  expanded: [],
  selected: []
};

function findNodeById(node: FileSystemItem, id: string): FileSystemItem {
  if (node.id === id) {
    return node;
  }

  if (Array.isArray(node.children)) {
    for (const child of node.children) {
      const found = findNodeById(child, id);
      if (found) {
        return found;
      }
    }
  }

  return null;
}

const slice = createSlice({
  name: 'files',
  initialState,
  reducers: {
    setFiles(state: FileState, action: PayloadAction<ProductFilesVm>) {
      state.productFiles = action.payload;
      state.expanded = action.payload.directoryIds;
      state.selected = [action.payload.files.id];
      state.selectedFiles = action.payload.files.children.filter(
        (file) => !file.isDirectory
      );
    },
    openSidebar(state: FileState) {
      state.isSidebarOpen = true;
    },
    closeSidebar(state: FileState) {
      state.isSidebarOpen = false;
    },
    setExpanded(state: FileState, action: PayloadAction<string[]>) {
      state.expanded = action.payload;
    },
    setSelected(state: FileState, action: PayloadAction<string>) {
      state.selected = [action.payload];
      let selectedNode = findNodeById(state.productFiles.files, action.payload);
      if (selectedNode) {
        state.selectedFiles = selectedNode.children.filter(
          (file) => !file.isDirectory
        );
      }
    }
  }
});
//------------------------------------------------------------------

export const reducer = slice.reducer;

//------------------------------------------------------------------

export const getFiles = (id: number): AppThunk => async (dispatch) => {
  const { data = null } = await axios.get<ProductFilesVm>(
    `/api/products/${id}/files`
  );

  dispatch(slice.actions.setFiles(data));
};

export const downloadFile = (path: string): AppThunk => async (dispatch) => {
  axios({
    url: '/api/files/products',
    method: 'POST',
    responseType: 'blob',
    data: {
      path: path
    }
  }).then((response) => {
    const blobUrl = URL.createObjectURL(new Blob([response.data]));

    // Create a link element
    const link = document.createElement('a');

    // Set link's href to point to the Blob URL
    link.href = blobUrl;
    link.download = path.split('/').pop();

    // Append link to the body
    document.body.appendChild(link);

    // Dispatch click event on the link
    // This is necessary as link.click() does not work on the latest firefox
    link.dispatchEvent(
      new MouseEvent('click', {
        bubbles: true,
        cancelable: true,
        view: window
      })
    );

    // Remove link from body
    document.body.removeChild(link);
  });
};

export const handleToggle = (nodeIds: string[]): AppThunk => async (
  dispatch
) => {
  dispatch(slice.actions.setExpanded(nodeIds));
};

export const handleSelect = (nodeId: string): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setSelected(nodeId));
};

export const openSidebar = (): AppThunk => async (dispatch) => {
  dispatch(slice.actions.openSidebar());
};

export const closeSidebar = (): AppThunk => async (dispatch) => {
  dispatch(slice.actions.closeSidebar());
};

export default slice;
