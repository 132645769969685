import { combineReducers } from '@reduxjs/toolkit';
import { reducer as calendarReducer } from 'src/slices/calendar';
import { reducer as chatReducer } from 'src/slices/chat';
import { reducer as formReducer } from 'redux-form';
import { reducer as kanbanReducer } from 'src/slices/kanban';
import { reducer as mailReducer } from 'src/slices/mail';
import { reducer as notificationReducer } from 'src/slices/notification';
import { reducer as calculatorReducer } from 'src/slices/calculator';
import { reducer as deadloadReducer } from 'src/slices/deadload';
import { reducer as ezsubReducer } from 'src/slices/ezsub';
import { reducer as filesReducer } from 'src/slices/files';

const rootReducer = combineReducers({
  calendar: calendarReducer,
  chat: chatReducer,
  form: formReducer,
  kanban: kanbanReducer,
  mail: mailReducer,
  notifications: notificationReducer,
  calculator: calculatorReducer,
  deadload: deadloadReducer,
  ezsub: ezsubReducer,
  files: filesReducer
});

export default rootReducer;
