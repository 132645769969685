import * as React from 'react';
import createSvgIcon from 'src/utils/createSvgIcon';

export const CustomOrder = createSvgIcon(
  <g>
    <path
      d="m2.8892,1.69502c-0.18046,0.45324 -0.35672,0.89809 -0.54977,1.38491c-0.26439,0 -0.58754,0 -0.93167,0c0,5.06962 0,10.11825 0,15.18367c3.93651,0 7.87721,0 11.83051,0c0,-5.05283 0,-10.10566 0,-15.17528c-0.33993,0 -0.67567,0 -0.94426,0c-0.19305,-0.48682 -0.36931,-0.93167 -0.55396,-1.38911c0.01679,0 0.08393,-0.00839 0.15108,-0.00839c0.6421,0 1.28,-0.0042 1.92209,0c0.56655,0.0042 0.83095,0.27698 0.83095,0.83514c0,5.43054 0,10.86527 0,16.2958c0,0.59593 -0.256,0.85193 -0.84773,0.85193c-4.28064,0 -8.56547,0 -12.84611,0c-0.05875,0 -0.11751,0 -0.17626,0c-0.49941,-0.0042 -0.77219,-0.27279 -0.77219,-0.768c-0.0042,-0.90229 0,-1.80458 0,-2.70687c0,-4.29742 0,-8.59065 0,-12.88808c0,-0.30636 -0.0042,-0.61272 0,-0.91908c0.00839,-0.3861 0.2602,-0.67987 0.6421,-0.69246c0.74701,-0.01679 1.49822,-0.0042 2.24524,-0.0042c0.01259,0.0042 0.02518,0.01679 0,0l-0.00002,0.00002z"
      fill="currentColor"
      id="svg_3"
      stroke="null"
    />
    <path
      d="m5.46178,1.67403c-0.00839,-0.58334 0.19724,-1.04498 0.65049,-1.38072c0.24341,-0.18046 0.52039,-0.28957 0.83095,-0.28957c0.27698,0 0.55397,-0.01259 0.83095,0.00839c0.66728,0.04197 1.27999,0.6337 1.35973,1.30098c0.01259,0.11331 0.02518,0.23082 0.03357,0.33993c0.32734,0.02098 0.6421,0.01259 0.94845,0.05875c0.768,0.1259 1.30518,0.82255 1.2716,1.61153c-0.03777,0.83514 -0.60852,1.48563 -1.41009,1.5318c-0.67567,0.03777 -1.35134,0.02098 -2.03121,0.02098c-1.0198,0 -2.0396,0.0042 -3.0552,-0.0042c-0.86032,-0.0042 -1.46465,-0.48262 -1.62832,-1.28419c-0.20564,-0.97783 0.52459,-1.89691 1.5234,-1.91789c0.21403,0.0042 0.43226,0.0042 0.67567,0.0042l0.00001,0.00001z"
      fill="currentColor"
      id="svg_4"
      stroke="null"
    />
    <path
      d="m6.39344,12.85825c0.55816,-0.69246 1.09954,-1.36812 1.64511,-2.0396c0.52039,-0.64629 1.04078,-1.29258 1.56537,-1.93468c0.19724,-0.24341 0.45744,-0.31475 0.74282,-0.24341c0.26019,0.06295 0.42387,0.24341 0.48262,0.512c0.04617,0.22662 -0.04197,0.41128 -0.18046,0.58334c-0.94845,1.17088 -1.89271,2.34596 -2.83697,3.51684c-0.27279,0.33993 -0.54557,0.67986 -0.82255,1.0198c-0.31056,0.3819 -0.78059,0.39869 -1.11213,0.03357c-0.51619,-0.57075 -1.02819,-1.1499 -1.54019,-1.72484c-0.27279,-0.31056 -0.26859,-0.67567 0.0042,-0.93587c0.27279,-0.2602 0.71344,-0.256 0.98203,0.02938c0.33154,0.34833 0.6421,0.71344 0.96524,1.07016c0.02098,0.02938 0.05036,0.05876 0.10492,0.11331l-0.00001,0z"
      fill="currentColor"
      id="svg_5"
      stroke="null"
    />
  </g>,
  'CustomOrder'
);
