/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { AppThunk } from 'src/store';
import { ExtrusionDto, SteelDto, SystemDto, SystemsVm } from 'src/lib/api';
import {
  MemberIndex,
  IMemberStack,
  IGeneralProps,
  General,
  SpanSize,
  MemberStack
} from 'src/types/calculator';

interface CalculatorState {
  systemData: SystemDto[];
  steelData: Array<SteelDto>;
  systems: string[];
  selectedSystem: SystemDto;
  memberStacks: IMemberStack[];
  centerlineWidths: Array<number | string>;
  generalProps: IGeneralProps;
}

// Helper Functions
const resetStack = (index: number, members?: ExtrusionDto[]) => {
  return {
    index: index,
    filteredMembers: members,
    stress: null,
    stressPass: null,
    deflectionLow: null,
    deflectionLowPass: null,
    deflectionUp: null,
    deflectionUpPass: null,
    reactionHead: null,
    reactionAnchor: null,
    reactionSill: null,
    requiredIx: null,
    requiredSx: null,
    selectedMember: {
      id: 0,
      ix: 0,
      extrusionName: ''
    },
    selectedMemberType: '',
    filteredSteel: [],
    selectedSteel: {
      id: 0,
      ixx: 0,
      name: ''
    },
    needsSteelOptimization: false,
    requiredSteelIx: null,
    variables: null
  } as IMemberStack;
};

const defaultStacks = (members?: ExtrusionDto[]) =>
  [...Array(6)].map((e, i) => {
    return resetStack(i, members);
  });

const clearResults = (stacks: IMemberStack[]) =>
  stacks.map((stack, i) => {
    const m: IMemberStack = {
      ...stack,
      stress: null,
      stressPass: null,
      deflectionLow: null,
      deflectionLowPass: null,
      deflectionUp: null,
      deflectionUpPass: null,
      reactionHead: null,
      reactionAnchor: null,
      reactionSill: null,
      requiredIx: null,
      requiredSx: null
    };
    return m;
  });

const clearGeneral = () => {
  return {
    spanSize: SpanSize.SINGLE,
    windLoad: '',
    jambCaulkJointWidth: '',
    overrideDeflection: false,
    spanHeightFt: '',
    spanHeightIn: '',
    spacingFt: '',
    spacingIn: '',
    midspanHeightFt: '',
    midspanHeightIn: '',
    lowCriteriaL: '',
    lowCriteriaAdd: '',
    upCriteriaL: '',
    upCriteriaAdd: '',
    spacingError: false,
    spanlowError: false
  };
};

const recalculate = (state: CalculatorState) => {
  const generalProps = new General(state.generalProps);
  state.generalProps.spacingError = generalProps.spacingError;
  state.generalProps.spanlowError = generalProps.spanlowError;

  if (generalProps.isValid) {
    //Populate Deflection Criteria
    if (!generalProps.overrideDeflection) {
      if (generalProps.spanSize === SpanSize.SINGLE) {
        if (generalProps.span > 162) {
          state.generalProps.lowCriteriaL = 240;
          state.generalProps.lowCriteriaAdd = 0.25;
        } else if (generalProps.spacing < 131.25) {
          state.generalProps.lowCriteriaL = 175;
          state.generalProps.lowCriteriaAdd = 0;
        } else {
          state.generalProps.lowCriteriaL = generalProps.spacing / 0.75;
          state.generalProps.lowCriteriaAdd = 0;
        }
      } else if (
        generalProps.spanSize === SpanSize.TWIN &&
        generalProps.spanLow > 0
      ) {
        if (generalProps.spanLow > 162) {
          state.generalProps.lowCriteriaL = 240;
          state.generalProps.lowCriteriaAdd = 0.25;
        } else {
          state.generalProps.lowCriteriaL = 175;
          state.generalProps.lowCriteriaAdd = 0;
        }
        if (generalProps.spanUp > 162) {
          state.generalProps.upCriteriaL = 240;
          state.generalProps.upCriteriaAdd = 0.25;
        } else {
          state.generalProps.upCriteriaL = 175;
          state.generalProps.upCriteriaAdd = 0;
        }
      }
    }

    for (let index = 0; index < state.memberStacks.length; index++) {
      const stack = state.memberStacks[index];
      //If member isn't selected, skip
      if (stack.selectedMember.id === 0) continue;

      var width1 =
        index === 0
          ? state.centerlineWidths[0]
          : state.centerlineWidths[index - 1];
      // calculate width2. 1st and last stack get 0 for width2
      var width2: number | string = 0;
      if (index > 0 && index < 5) width2 = state.centerlineWidths[index] ?? 0;

      var memberStack = new MemberStack(
        generalProps,
        width1,
        width2,
        stack.selectedMember,
        stack.selectedSteel
      );
      const result = memberStack.calculateSpan();
      state.memberStacks[index].stress = result.stress;
      state.memberStacks[index].stressPass = result.stressPass;
      state.memberStacks[index].deflectionLow = result.deflectionLow;
      state.memberStacks[index].deflectionLowPass = result.deflectionLowPass;
      state.memberStacks[index].deflectionUp = result.deflectionUp;
      state.memberStacks[index].deflectionUpPass = result.deflectionUpPass;
      state.memberStacks[index].reactionHead = result.reactionHead;
      state.memberStacks[index].reactionSill = result.reactionSill;
      state.memberStacks[index].reactionAnchor = result.reactionAnchor;
      state.memberStacks[index].requiredIx = result.requiredIx;
      state.memberStacks[index].requiredSx = result.requiredSx;
      state.memberStacks[index].ix = result.ix;
      state.memberStacks[index].sx = result.sx;
      state.memberStacks[index].spanHeightError = result.spanHeightError;
      state.memberStacks[index].variables = result.variables;

      //Repopulate steel dropdown if steel optimization is required
      // if (result.needsSteelOptimization) {
      const steelOptimization = result.requiredSteelIx > 0;
      state.memberStacks[index].filteredSteel = state.steelData
        .filter((steel) => {
          var match = false;
          if (
            (steel.maxDepth <=
              state.memberStacks[index].selectedMember.maxSteelDepth &&
              steel.maxWidth <=
                state.memberStacks[index].selectedMember.maxSteelWidth) ||
            (steel.fitType &&
              steel.fitType ===
                state.memberStacks[index].selectedMember.specialSteelFitType)
          ) {
            if (!steelOptimization) {
              match = true;
            } else if (steel.ixx >= result.requiredSteelIx) {
              match = true;
            }
          }
          return match;
        })
        .sort((a, b) => {
          var result = b.availability.localeCompare(a.availability);
          if (result === 0) result = a.ixx - b.ixx;

          return result;
        });
      // }
    }
  } else {
    state.memberStacks = clearResults(state.memberStacks);
  }
};

const initialState: CalculatorState = {
  systemData: null,
  steelData: null,
  systems: [],
  selectedSystem: {
    name: '',
    id: 0,
    members: []
  },
  memberStacks: defaultStacks([]),
  centerlineWidths: ['', '', '', '', ''],
  generalProps: clearGeneral()
};

const slice = createSlice({
  name: 'calculator',
  initialState,
  reducers: {
    getSystems(state: CalculatorState, action: PayloadAction<SystemsVm>): void {
      state.systemData = action.payload.systems;
      state.steelData = action.payload.steels;
      state.systems = action.payload.systems?.map(({ name }) => name);
    },
    selectSystem(state: CalculatorState, action: PayloadAction<string>) {
      const systemName = action.payload;
      if (systemName) {
        const foundSystem = state.systemData.find((s) => s.name === systemName);
        state.selectedSystem = foundSystem;
        const members =
          state.generalProps.spanSize === SpanSize.SINGLE
            ? foundSystem.members
            : foundSystem.members.filter((member) => member.maxSpan > 14);
        state.memberStacks = defaultStacks(members);
      }
    },
    selectMember(state: CalculatorState, action: PayloadAction<MemberIndex>) {
      const memberIndex = action.payload;
      if (memberIndex) {
        const { index, value } = memberIndex;
        if (value) {
          //reset steel selection
          state.memberStacks[index].selectedSteel = {
            id: 0,
            ixx: 0,
            name: ''
          };
          state.memberStacks[index].selectedMember = state.memberStacks[
            index
          ].filteredMembers.find((m) => m.id === value);
          state.memberStacks[index].filteredSteel = state.steelData
            .filter((item) => {
              return state.memberStacks[index].selectedMember.steelIds.includes(
                item.id
              );
            })
            .sort((a, b) => {
              var result = b.availability.localeCompare(a.availability);
              if (result === 0) result = a.ixx - b.ixx;

              return result;
            });
          recalculate(state);
        } else {
          state.memberStacks[index].selectedMember = {
            id: 0,
            ix: 0,
            extrusionName: ''
          };
        }
      }
    },
    selectSteel(state: CalculatorState, action: PayloadAction<MemberIndex>) {
      const memberIndex = action.payload;
      if (memberIndex) {
        const { index, value } = memberIndex;
        if (value) {
          state.memberStacks[index].selectedSteel = state.memberStacks[
            index
          ].filteredSteel.find((m) => m.id === value);
          recalculate(state);
        }
      }
    },
    selectMemberType(
      state: CalculatorState,
      action: PayloadAction<MemberIndex>
    ) {
      const memberIndex = action.payload;
      if (memberIndex) {
        const { index, value } = memberIndex;
        state.memberStacks[index].selectedMemberType = value;
        state.memberStacks[
          index
        ].filteredMembers = state.selectedSystem.members.filter(function (
          member: ExtrusionDto
        ) {
          if (!value) return true;

          switch (value) {
            case 'Vertical':
              return member.isVertical;
            case 'Jamb':
              return member.isJamb;
            case 'Door Jamb':
              return member.isDoorJamb;
            case 'Outside Corner':
              return member.isOutsideCorner;
            case 'Inside Corner':
              return member.isInsideCorner;
          }

          return false;
        });
      }
    },
    handleGeneralPropChange(
      state: CalculatorState,
      action: PayloadAction<Object>
    ) {
      const obj = action.payload;
      state.generalProps = {
        ...state.generalProps,
        ...obj
      };
      //filter systems if twin span
      if (obj.hasOwnProperty('spanSize') && obj['spanSize'] === SpanSize.TWIN) {
        state.systems = state.systemData
          .filter((system) =>
            system.members.find((member) => member.maxSpan > 14)
          )
          .map((s) => s.name);
        if (
          state.selectedSystem &&
          !state.systems.includes(state.selectedSystem.name)
        ) {
          state.selectedSystem = {
            name: '',
            id: 0,
            members: []
          };
          state.memberStacks = defaultStacks([]);
        } else {
          const filteredMembers = state.selectedSystem.members.filter(
            (member) => member.maxSpan > 14
          );
          state.memberStacks = defaultStacks(filteredMembers);
          state.selectedSystem.members = filteredMembers;
        }
      } else {
        state.systems = state.systemData.map((s) => s.name);
      }
      recalculate(state);
    },
    handleCenterlineChange(
      state: CalculatorState,
      action: PayloadAction<MemberIndex>
    ) {
      const memberIndex = action.payload;
      if (memberIndex) {
        const { index, value } = memberIndex;
        state.centerlineWidths[index] = value;
        recalculate(state);
      }
    },
    handleClear(state: CalculatorState) {
      state.generalProps = clearGeneral();
      state.memberStacks = defaultStacks([]);
      state.centerlineWidths = ['', '', '', '', ''];
      state.selectedSystem = {
        name: '',
        id: 0,
        members: []
      };
      recalculate(state);
    }
  }
});

export const reducer = slice.reducer;

export const getSystems = (
  getCalculatorSystems: () => Promise<SystemsVm>
): AppThunk => async (dispatch): Promise<void> => {
  // I don't like having to pass a function to call here
  // can we just pass the api call directly to the file instead
  // through an import?
  const systems = await getCalculatorSystems();

  dispatch(slice.actions.getSystems(systems));
};

export const selectSystem = (systemName: string): AppThunk => async (
  dispatch
) => {
  dispatch(slice.actions.selectSystem(systemName));
};

export const selectMember = (memberIndex: MemberIndex): AppThunk => async (
  dispatch
) => {
  dispatch(slice.actions.selectMember(memberIndex));
};

export const selectSteel = (memberIndex: MemberIndex): AppThunk => async (
  dispatch
) => {
  dispatch(slice.actions.selectSteel(memberIndex));
};

export const selectMemberType = (memberIndex: MemberIndex): AppThunk => async (
  dispatch
) => {
  dispatch(slice.actions.selectMemberType(memberIndex));
};

export const handleGeneralPropChange = (obj: Object): AppThunk => async (
  dispatch
) => {
  dispatch(slice.actions.handleGeneralPropChange(obj));
};

export const handleClear = (): AppThunk => async (dispatch) => {
  dispatch(slice.actions.handleClear());
};

export const handleCenterlineChange = (
  memberIndex: MemberIndex
): AppThunk => async (dispatch) => {
  dispatch(slice.actions.handleCenterlineChange(memberIndex));
};

export default slice;
