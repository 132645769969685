import React from 'react';
import type { FC } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Box,
  Container,
  Divider,
  Grid,
  Typography,
  makeStyles
} from '@material-ui/core';
import type { Theme } from 'src/theme';

interface FAQSProps {
  className?: string;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    paddingTop: theme.spacing(6),
    paddingBottom: theme.spacing(6),
    '& dt': {
      marginTop: theme.spacing(2)
    }
  }
}));

const FAQS: FC<FAQSProps> = ({ className, ...rest }) => {
  const classes = useStyles();

  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <Container maxWidth="lg">
        <Typography variant="h1" color="textPrimary">
          Frequently asked questions
        </Typography>
        <Box my={3}>
          <Divider />
        </Box>
        <Grid container spacing={3} component="dl">
          <Grid item xs={12} md={6}>
            <Typography variant="overline" color="secondary">
              Registration
            </Typography>
            <Box mt={6}>
              <dt>
                <Typography variant="h4" color="textPrimary">
                  How do I register?
                </Typography>
              </dt>
              <dd>
                <Typography variant="body1" color="textSecondary">
                  Click on 'Register' then the 'Sign Up' tab. You will need to
                  know your customer number and its postal code.
                </Typography>
              </dd>
            </Box>
            <Box mt={6}>
              <dt>
                <Typography variant="h4" color="textPrimary">
                  Can multiple employees from our orginzation register for
                  accounts?
                </Typography>
              </dt>
              <dd>
                <Typography variant="body1" color="textSecondary">
                  Absolutely! You will each register for an account, and provide
                  the same customer number and related postal code.
                </Typography>
              </dd>
            </Box>
            <Box mt={6}>
              <dt>
                <Typography variant="h4" color="textPrimary">
                  I am having trouble registering, who can I contact?
                </Typography>
              </dt>
              <dd>
                <Typography variant="body1" color="textSecondary">
                  Please email us at{' '}
                  <a href={`mailto:dependable@tubeliteusa.com`}>
                    dependable@tubeliteusa.com
                  </a>{' '}
                  and we will help you you register.
                </Typography>
              </dd>
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="overline" color="secondary">
              Portal
            </Typography>
            <Box mt={6}>
              <dt>
                <Typography variant="h4" color="textPrimary">
                  How do I get my order acknowledgment?
                </Typography>
              </dt>
              <dd>
                <Typography variant="body1" color="textSecondary">
                  After you have registered for an account, login to the portal.
                  On the orders page you can click on the PDF icon for the order
                  and the acknowledgment will start to download.
                </Typography>
              </dd>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

FAQS.propTypes = {
  className: PropTypes.string
};

export default FAQS;
