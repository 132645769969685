import * as React from 'react';
import createSvgIcon from 'src/utils/createSvgIcon';

export const StructuralCalculator = createSvgIcon(
  <g stroke="null" id="svg_1">
    <rect
      stroke="currentColor"
      id="svg_2"
      strokeWidth="1.3321"
      fill="none"
      height="6.19647"
      width="13.68772"
      y="3.25083"
      x="5.98838"
    />
    <rect
      stroke="currentColor"
      id="svg_3"
      strokeWidth="1.3321"
      fill="none"
      height="6.19647"
      width="2.66818"
      y="3.25083"
      x="0"
    />
    <line
      stroke="currentColor"
      id="svg_4"
      strokeWidth="1.3321"
      fill="currentColor"
      y2="4.68897"
      x2="3.52366"
      y1="0"
      x1="3.52366"
    />
    <line
      stroke="currentColor"
      id="svg_5"
      strokeWidth="1.3321"
      fill="currentColor"
      y2="4.68897"
      x2="5.10053"
      y1="0"
      x1="5.10053"
    />
    <line
      stroke="currentColor"
      id="svg_6"
      strokeWidth="1.3321"
      fill="currentColor"
      y2="12.79987"
      x2="3.52366"
      y1="8.1109"
      x1="3.52366"
    />
    <line
      stroke="currentColor"
      id="svg_7"
      strokeWidth="1.3321"
      fill="currentColor"
      y2="12.79987"
      x2="5.10053"
      y1="8.1109"
      x1="5.10053"
    />
    <rect
      stroke="currentColor"
      id="svg_8"
      strokeWidth="0.6849"
      fill="none"
      height="2.09478"
      width="2.08553"
      y="5.29937"
      x="3.3202"
    />
    <g stroke="null" id="svg_9">
      <circle
        stroke="currentColor"
        id="svg_10"
        strokeWidth="2.6177"
        fill="none"
        r="4.59186"
        cy="6.29358"
        cx="12.75363"
      />
      <line
        stroke="currentColor"
        id="svg_11"
        strokeLinecap="round"
        strokeWidth="3.054"
        fill="currentColor"
        y2="13.52587"
        x2="17.96051"
        y1="10.18255"
        x1="15.55591"
      />
    </g>
  </g>,
  'StructuralCalculator'
);
