import * as React from 'react';
import createSvgIcon from 'src/utils/createSvgIcon';

export const InstallCert = createSvgIcon(
  <g stroke="null" id="svg_10">
    <g stroke="null" id="svg_1">
      <path
        stroke="null"
        id="svg_2"
        fill="currentColor"
        d="m12.73783,0c-2.17548,0 -3.94226,1.76252 -3.94226,3.94226s1.76252,3.94225 3.94226,3.94225s3.94225,-1.76252 3.94225,-3.94225s-1.76678,-3.94226 -3.94225,-3.94226zm1.68163,6.13476l-1.68163,-1.22184l-1.68163,1.22184l0.64285,-1.97539l-1.68163,-1.22184l2.07756,0l0.64285,-1.97539l0.64285,1.97539l2.07756,0l-1.68163,1.22184l0.64285,1.97539z"
      />
      <path
        stroke="null"
        id="svg_3"
        fill="currentColor"
        d="m15.07934,16.35227l-1.36233,-1.06007l0,3.16743l-12.35467,0l0,-14.90905l6.68395,0c0.05109,-0.48533 0.19158,-0.94512 0.40444,-1.36233l-7.68016,0c-0.42573,0 -0.77057,0.34484 -0.77057,0.77057l0,16.09257c0,0.42573 0.34484,0.77057 0.77057,0.77057l13.54671,0c0.42573,0 0.77057,-0.34484 0.77057,-0.77057c0,-0.02554 -0.00426,-0.04683 -0.00852,-0.06812l0,-2.63101z"
      />
      <path
        stroke="null"
        id="svg_4"
        fill="currentColor"
        d="m10.15791,7.68867l0,7.74402l2.59269,-2.08182l2.60972,2.08182l0,-7.74402c-0.69394,0.61305 -1.605,0.98769 -2.60121,0.98769c-0.99621,-0.00426 -1.90301,-0.3789 -2.60121,-0.98769z"
      />
    </g>
    <path
      stroke="null"
      id="svg_5"
      fill="currentColor"
      d="m7.76105,5.29182l-4.84906,0l0,0.00426c-0.00851,0 -0.01703,-0.00426 -0.02129,-0.00426c-0.31078,0 -0.56622,0.25544 -0.56622,0.56622c0,0.31078 0.25544,0.56622 0.56622,0.56622c0.00851,0 0.01703,0 0.02129,-0.00426l0,0.00426l4.84906,0c0.31078,0 0.56622,-0.25544 0.56622,-0.56622c-0.00426,-0.31078 -0.25544,-0.56622 -0.56622,-0.56622z"
    />
    <path
      stroke="null"
      id="svg_6"
      fill="currentColor"
      d="m7.76105,7.63759l-4.84906,0l0,0.00426c-0.00851,0 -0.01703,-0.00426 -0.02129,-0.00426c-0.31078,0 -0.56622,0.25544 -0.56622,0.56622c0,0.31078 0.25544,0.56622 0.56622,0.56622c0.00851,0 0.01703,0 0.02129,-0.00426l0,0.00426l4.84906,0c0.31078,0 0.56622,-0.25544 0.56622,-0.56622c-0.00426,-0.31504 -0.25544,-0.56622 -0.56622,-0.56622z"
    />
    <path
      stroke="null"
      id="svg_7"
      fill="currentColor"
      d="m7.76105,9.93227l-4.84906,0l0,0.00426c-0.00851,0 -0.01703,-0.00426 -0.02129,-0.00426c-0.31078,0 -0.56622,0.25544 -0.56622,0.56622c0,0.31078 0.25544,0.56622 0.56622,0.56622c0.00851,0 0.01703,0 0.02129,-0.00426l0,0.00426l4.84906,0c0.31078,0 0.56622,-0.25544 0.56622,-0.56622c-0.00426,-0.31078 -0.25544,-0.56622 -0.56622,-0.56622z"
    />
    <path
      stroke="null"
      id="svg_8"
      fill="currentColor"
      d="m7.76105,12.27804l-4.84906,0l0,0.00426c-0.00851,0 -0.01703,-0.00426 -0.02129,-0.00426c-0.31078,0 -0.56622,0.25544 -0.56622,0.56622c0,0.31078 0.25544,0.56622 0.56622,0.56622c0.00851,0 0.01703,0 0.02129,-0.00426l0,0.00426l4.84906,0c0.31078,0 0.56622,-0.25544 0.56622,-0.56622c-0.00426,-0.31504 -0.25544,-0.56622 -0.56622,-0.56622z"
    />
    <path
      stroke="null"
      id="svg_9"
      fill="currentColor"
      d="m7.76105,15.63279c0.31078,0 0.56622,-0.25544 0.56622,-0.56622c0,-0.31079 -0.25544,-0.56622 -0.56622,-0.56622l-4.84906,0l0,0c-0.00851,0 -0.01703,0 -0.02129,0c-0.31078,0 -0.56622,0.25544 -0.56622,0.56622c0,0.31078 0.25544,0.56622 0.56622,0.56622c0.00851,0 0.01703,0 0.02129,-0.00426l0,0.00426l4.84906,0z"
    />
  </g>,
  'InstallCert'
);
