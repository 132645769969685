import React, { createContext } from 'react';
import type { FC, ReactNode } from 'react';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import {
  OrdersApi,
  ShipmentsApi,
  CustomersApi,
  SystemsApi,
  BackmembersApi,
  FormSubmissionsApi,
  MessagesApi,
  UsersApi,
  UpdateUserCommand,
  FormVm,
  NewVm,
  NewApi,
  UpdateUserMessageCommand,
  CalculatorApi,
  SystemsVm,
  PrintCalculatorCommand,
  PrintDeadloadCommand,
  CustomerVm,
  ProjectVm,
  ProjectsApi,
  CreateProjectCommand,
  ProductCategoryDto,
  ProductsApi,
  CreateSubmittalCommand,
  SubmittalsApi,
  ProductPropertyDto,
  SubstitutionDto
} from 'src/lib/api';
import useAuth from '../hooks/useAuth';

export interface ApiContextValue {
  getOrders: () => Promise<any>;
  getOrderById: (orderId) => Promise<any>;
  getCustomers: () => Promise<any>;
  getCustomerById: (id) => Promise<CustomerVm>;
  getSystems: (options) => Promise<any>;
  getBackmembers: (system) => Promise<any>;
  getBackmemberPoints: (system, backmember) => Promise<any>;
  getAcknowledgement: (orderId) => Promise<any>;
  getShipping: (type, filename) => Promise<any>;
  getShipments: () => Promise<any>;
  getMessages: () => Promise<any>;
  getMessageById: (id) => Promise<any>;
  sendMessage: (message) => Promise<any>;
  submitForm: (name, formData) => Promise<any>;
  getUser: (userId: string) => Promise<any>;
  getUsers: () => Promise<any>;
  updateUser: (id: string, user: UpdateUserCommand) => Promise<any>;
  updateUserMessage: (message: UpdateUserMessageCommand) => Promise<any>;
  deleteUserMessage: (id) => Promise<any>;
  deleteUserMessages: (ids) => Promise<any>;
  getFormSubmissions: () => Promise<any>;
  getFormSubmissionById: (id: number) => Promise<FormVm>;
  getNewItems: () => Promise<NewVm>;
  createInstallerVerification: (name, formdData) => Promise<any>;
  createStrutCalcPdf: (printCommand: PrintCalculatorCommand) => Promise<any>;
  createDeadloadCalcPdf: (command: PrintDeadloadCommand) => Promise<any>;
  getCalculatorSystems: () => Promise<any>;
  calculatorUploadData: (files: any[]) => Promise<any>;
  getUserLogins: () => Promise<any>;
  getProjects: () => Promise<ProjectVm[]>;
  getProjectById: (id: number) => Promise<ProjectVm>;
  createProject: (project: CreateProjectCommand) => Promise<number>;
  addProjectSubstitutions: (
    id: number,
    substitutions: Array<SubstitutionDto>
  ) => Promise<any>;
  createProjectPdf: (id: number) => Promise<any>;
  getProducts: () => Promise<ProductCategoryDto[]>;
  getProductProperties: (id: number) => Promise<ProductPropertyDto[]>;
  createSubmittal: (submittal: CreateSubmittalCommand) => Promise<number>;
  createSubmittalPdf: (id: number) => Promise<any>;
}

interface ApiProviderProps {
  children: ReactNode;
}

const ApiContext = createContext<ApiContextValue>({
  getOrders: () => Promise.resolve(),
  getOrderById: () => Promise.resolve(),
  getCustomers: () => Promise.resolve(),
  getCustomerById: () => Promise.resolve({} as CustomerVm),
  getSystems: () => Promise.resolve(),
  getBackmembers: () => Promise.resolve(),
  getBackmemberPoints: () => Promise.resolve(),
  getAcknowledgement: () => Promise.resolve(),
  getShipping: () => Promise.resolve(),
  getShipments: () => Promise.resolve(),
  getMessages: () => Promise.resolve(),
  getMessageById: () => Promise.resolve(),
  sendMessage: () => Promise.resolve(),
  submitForm: () => Promise.resolve(),
  getUser: () => Promise.resolve(),
  getUsers: () => Promise.resolve(),
  updateUser: () => Promise.resolve(),
  updateUserMessage: () => Promise.resolve(),
  deleteUserMessage: () => Promise.resolve(),
  deleteUserMessages: () => Promise.resolve(),
  getFormSubmissions: () => Promise.resolve(),
  getFormSubmissionById: () => Promise.resolve({} as FormVm),
  getNewItems: () => Promise.resolve({} as NewVm),
  createInstallerVerification: () => Promise.resolve(),
  createStrutCalcPdf: () => Promise.resolve({} as PrintCalculatorCommand),
  createDeadloadCalcPdf: () => Promise.resolve({} as PrintDeadloadCommand),
  getCalculatorSystems: () => Promise.resolve(),
  calculatorUploadData: () => Promise.resolve(),
  getUserLogins: () => Promise.resolve(),
  getProjects: () => Promise.resolve([] as ProjectVm[]),
  getProjectById: () => Promise.resolve({} as ProjectVm),
  createProject: () => Promise.resolve({} as number),
  addProjectSubstitutions: () => Promise.resolve({} as any),
  createProjectPdf: () => Promise.resolve({} as any),
  getProducts: () => Promise.resolve([] as ProductCategoryDto[]),
  getProductProperties: () => Promise.resolve([] as ProductPropertyDto[]),
  createSubmittal: () => Promise.resolve({} as number),
  createSubmittalPdf: () => Promise.resolve({} as any)
});

export const ApiProvider: FC<ApiProviderProps> = ({
  children
}: ApiProviderProps) => {
  const { accessToken, user } = useAuth();
  const history = useHistory();
  axios.defaults.baseURL = window.location.origin;
  var customerHeaderValue = user?.details?.customer?.id;
  axios.defaults.headers.common = {
    'Cache-Control': 'no-cache',
    Pragma: 'no-cache',
    Expires: '0',
    Authorization: `Bearer ${accessToken}`,
    'X-Customer': customerHeaderValue
  };

  const getOrders = async () => {
    try {
      const api = new OrdersApi(undefined, window.location.origin, axios);
      const { data: { orders } = {} } = await api.ordersGet();
      return orders;
    } catch (error) {
      console.error(error);
      return [];
    }
  };

  const getOrderById = async (orderId) => {
    try {
      const api = new OrdersApi(undefined, window.location.origin, axios);
      const { data = {} } = await api.ordersGetById(orderId);
      return data;
    } catch (error) {
      console.error(error);
      return [];
    }
  };

  const getAcknowledgement = async (orderId) => {
    try {
      const api = new OrdersApi(
        undefined,
        window.location.origin,
        axios.create({
          responseType: 'blob',
          timeout: 30000
        })
      );
      const { data } = await api.ordersGetAcknowledgement(orderId);
      return data;
    } catch (error) {
      console.error(error);
      return {};
    }
  };

  const getUserLogins = async () => {
    try {
      const api = new UsersApi(
        undefined,
        window.location.origin,
        axios.create({
          responseType: 'blob',
          timeout: 30000
        })
      );
      const { data } = await api.usersExportLogin();
      return data;
    } catch (error) {
      console.error(error);
      return {};
    }
  };

  const getShipping = async (type, filename) => {
    try {
      const api = new ShipmentsApi(
        undefined,
        window.location.origin,
        axios.create({
          responseType: 'blob',
          timeout: 30000
        })
      );
      const { data } = await api.shipmentsGetPdf(type, filename);
      return data;
    } catch (error) {
      console.error(error);
      return {};
    }
  };

  const getCustomers = async () => {
    try {
      const api = new CustomersApi(undefined, window.location.origin, axios);
      const { data: { customers } = {} } = await api.customersGet();
      return customers;
    } catch (error) {
      console.error(error);
      return [];
    }
  };

  const getCustomerById = async (id): Promise<CustomerVm> => {
    try {
      const api = new CustomersApi(undefined, window.location.origin, axios);
      const { data = {} } = await api.customersGetById(id);
      return data;
    } catch (error) {
      console.error(error);
      return {};
    }
  };

  const getSystems = async ({
    windload,
    deflection,
    heightIn,
    heightFt,
    leftIn,
    leftFt,
    rightIn,
    rightFt
  }) => {
    try {
      const api = new SystemsApi(undefined, window.location.origin, axios);
      const { data: { systemsList } = {} } = await api.systemsGet(
        windload,
        deflection,
        heightIn,
        heightFt,
        leftIn,
        leftFt,
        rightIn,
        rightFt
      );
      return systemsList;
    } catch (error) {
      console.error(error);
      return [];
    }
  };

  const getBackmembers = async ({
    system,
    windload,
    deflection,
    heightIn,
    heightFt,
    leftIn,
    leftFt,
    rightIn,
    rightFt
  }) => {
    try {
      const api = new BackmembersApi(undefined, window.location.origin, axios);
      const {
        data: { backmembersList = {}, properties = {} }
      } = await api.backmembersGet(
        system,
        windload,
        deflection,
        heightIn,
        heightFt,
        leftIn,
        leftFt,
        rightIn,
        rightFt
      );
      return { backmembersList, properties };
    } catch (error) {
      console.error(error);
      return [];
    }
  };

  const getBackmemberPoints = async (system, backmember) => {
    try {
      const api = new BackmembersApi(undefined, window.location.origin, axios);
      const { data = [] } = await api.backmembersGetPoints(system, backmember);
      return data;
    } catch (error) {
      console.error(error);
      return [];
    }
  };

  const getShipments = async () => {
    try {
      const api = new ShipmentsApi(undefined, window.location.origin, axios);
      const { data } = await api.shipmentsGet();
      return data;
    } catch (error) {
      console.error(error);
      return [];
    }
  };

  const getMessages = async () => {
    try {
      const api = new UsersApi(undefined, window.location.origin, axios);
      const { data: { messages } = {} } = await api.usersGetUserMessages();
      return messages;
    } catch (error) {
      console.error(error);
      return [];
    }
  };

  const getMessageById = async (id) => {
    try {
      const api = new MessagesApi(undefined, window.location.origin, axios);
      const { data = {} } = await api.messagesGetById(id);
      return data;
    } catch (error) {
      console.error(error);
      return {};
    }
  };

  const sendMessage = async (message) => {
    try {
      const api = new MessagesApi(undefined, window.location.origin, axios);
      const { data = {} } = await api.messagesCreate(message);
      return data;
    } catch (error) {
      console.error(error);
      return {};
    }
  };

  const updateUserMessage = async (message: UpdateUserMessageCommand) => {
    try {
      const api = new UsersApi(undefined, window.location.origin, axios);
      const { data = {} } = await api.usersUpdateMessage(message.id, message);
      return data;
    } catch (error) {
      console.error(error);
      return {};
    }
  };

  const deleteUserMessage = async (id) => {
    try {
      const api = new UsersApi(undefined, window.location.origin, axios);
      const { data = {} } = await api.usersDeleteUserMessage(id);
      return data;
    } catch (error) {
      console.error(error);
      return {};
    }
  };

  const deleteUserMessages = async (ids) => {
    try {
      const api = new UsersApi(undefined, window.location.origin, axios);
      const { data = {} } = await api.usersDeleteUserMessages(ids);
      return data;
    } catch (error) {
      console.error(error);
      return {};
    }
  };

  const submitForm = async (name, formData) => {
    try {
      const api = new FormSubmissionsApi(
        undefined,
        window.location.origin,
        axios
      );
      const { data } = await api.formSubmissionsCreate({
        formName: name,
        formData: JSON.stringify(formData)
      });
      return data;
    } catch (error) {
      console.error(error);
      return [];
    }
  };

  const getUser = async (id) => {
    try {
      const api = new UsersApi(undefined, window.location.origin, axios);
      const { data = {} } = await api.usersGetById(id);
      return data;
    } catch (error) {
      console.error(error);
      return {};
    }
  };

  const getUsers = async () => {
    try {
      const api = new UsersApi(undefined, window.location.origin, axios);
      const { data: { users } = {} } = await api.usersGet();
      return users;
    } catch (error) {
      console.error(error);
      return {};
    }
  };

  const getFormSubmissions = async () => {
    try {
      const api = new FormSubmissionsApi(
        undefined,
        window.location.origin,
        axios
      );
      const {
        data: { forms }
      } = await api.formSubmissionsGetAllForms();
      return forms;
    } catch (error) {
      if (error.response && error.response.status === 403) {
        history.push('/403');
      } else if (error.request) {
        // The request was made but no response was received
        console.log(error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log('Error', error.message);
      }
      return {};
    }
  };

  const getFormSubmissionById = async (id: number): Promise<FormVm> => {
    try {
      const api = new FormSubmissionsApi(
        undefined,
        window.location.origin,
        axios
      );
      const { data } = await api.formSubmissionsGetById(id);
      return data;
    } catch (error) {
      console.error(error);
      return {};
    }
  };

  const updateUser = async (id: string, user: UpdateUserCommand) => {
    const api = new UsersApi(undefined, window.location.origin, axios);
    await api.usersUpdate(id, user);
  };

  const getNewItems = async (): Promise<NewVm> => {
    try {
      const api = new NewApi(undefined, window.location.origin, axios);
      const { data } = await api.newGet();
      return data;
    } catch (error) {
      if (error.response && error.response.status === 403) {
        history.push('/403');
      } else if (error.response && error.response.status === 401) {
        history.push('/app');
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log('Error', error.message);
        if (!process.env.REACT_APP_BYPASS_OFFLINE) history.push('/offline');
      }
      return {};
    }
  };

  const createInstallerVerification = async (name, formData) => {
    try {
      const api = new FormSubmissionsApi(
        undefined,
        window.location.origin,
        axios.create({
          responseType: 'blob',
          timeout: 30000
        })
      );
      const { data } = await api.formSubmissionsCreateInstallerVerification({
        formName: name,
        formData: JSON.stringify(formData)
      });
      return data;
    } catch (error) {
      console.error(error);
      return {};
    }
  };

  const createStrutCalcPdf = async (printCommand: PrintCalculatorCommand) => {
    try {
      const api = new CalculatorApi(
        undefined,
        window.location.origin,
        axios.create({
          responseType: 'blob',
          timeout: 30000
        })
      );
      const { data } = await api.calculatorPrintCalculator(printCommand);
      return data;
    } catch (error) {
      console.error(error);
      return {};
    }
  };

  const createDeadloadCalcPdf = async (command: PrintDeadloadCommand) => {
    try {
      const api = new CalculatorApi(
        undefined,
        window.location.origin,
        axios.create({
          responseType: 'blob',
          timeout: 30000
        })
      );
      const { data } = await api.calculatorPrintDeadload(command);
      return data;
    } catch (error) {
      console.error(error);
      return {};
    }
  };

  const getCalculatorSystems = async (): Promise<SystemsVm> => {
    try {
      const api = new CalculatorApi(undefined, window.location.origin, axios);
      const { data = {} } = await api.calculatorGetSystems();
      return data;
    } catch (error) {
      console.error(error);
      return {};
    }
  };

  const calculatorUploadData = async (files: any[]): Promise<SystemsVm> => {
    try {
      const api = new CalculatorApi(undefined, window.location.origin, axios);
      const { data = {} } = await api.calculatorUploadData(files);
      //response
      // {
      //   "count": 2,
      //   "size": 210551
      // }
      return data;
    } catch (error) {
      console.error(error);
      return {};
    }
  };

  const getProjects = async (): Promise<Array<ProjectVm>> => {
    try {
      const api = new ProjectsApi(undefined, window.location.origin, axios);
      const { data: { projects } = {} } = await api.projectsGetProjects();
      return projects;
    } catch (error) {
      console.error(error);
      return [] as ProjectVm[];
    }
  };

  const getProjectById = async (id: number): Promise<ProjectVm> => {
    try {
      const api = new ProjectsApi(undefined, window.location.origin, axios);
      const { data = {} } = await api.projectsGetById(id);
      return data;
    } catch (error) {
      console.error(error);
      return {} as ProjectVm;
    }
  };

  const createProject = async (
    project: CreateProjectCommand
  ): Promise<number> => {
    const api = new ProjectsApi(undefined, window.location.origin, axios);
    const { data } = await api.projectsCreate(project);
    return data;
  };

  const addProjectSubstitutions = async (
    id: number,
    substitutions: Array<SubstitutionDto>
  ): Promise<any> => {
    const api = new ProjectsApi(undefined, window.location.origin, axios);
    await api.projectsAddSubstitutions(id, {
      projectId: id,
      substitutions: substitutions
    });
  };

  const createProjectPdf = async (id: number): Promise<any> => {
    try {
      const api = new ProjectsApi(
        undefined,
        window.location.origin,
        axios.create({
          responseType: 'blob',
          timeout: 30000
        })
      );
      const { data } = await api.projectsCreatePdf(id);
      return data;
    } catch (error) {
      console.error(error);
      return {};
    }
  };

  const getProducts = async (): Promise<Array<ProductCategoryDto>> => {
    try {
      const api = new ProductsApi(undefined, window.location.origin, axios);
      const { data: { productCategories } = {} } = await api.productsGet();
      return productCategories;
    } catch (error) {
      console.error(error);
      return [] as ProductCategoryDto[];
    }
  };

  const getProductProperties = async (
    id: number
  ): Promise<Array<ProductPropertyDto>> => {
    try {
      const api = new ProductsApi(undefined, window.location.origin, axios);
      const { data: { properties } = {} } = await api.productsGetProperties(id);
      return properties;
    } catch (error) {
      console.error(error);
      return [] as ProductPropertyDto[];
    }
  };

  const createSubmittal = async (
    command: CreateSubmittalCommand
  ): Promise<number> => {
    const api = new SubmittalsApi(undefined, window.location.origin, axios);
    const { data } = await api.submittalsCreate(command);
    return data;
  };

  const createSubmittalPdf = async (id: number): Promise<any> => {
    try {
      const api = new SubmittalsApi(
        undefined,
        window.location.origin,
        axios.create({
          responseType: 'blob',
          timeout: 30000
        })
      );
      const { data } = await api.submittalsCreatePdf(id);
      return data;
    } catch (error) {
      console.error(error);
      return {};
    }
  };

  return (
    <ApiContext.Provider
      value={{
        getOrders,
        getOrderById,
        getCustomers,
        getCustomerById,
        getSystems,
        getBackmembers,
        getBackmemberPoints,
        getAcknowledgement,
        getShipping,
        getShipments,
        getMessages,
        getMessageById,
        sendMessage,
        submitForm,
        getUser,
        getUsers,
        updateUser,
        updateUserMessage,
        deleteUserMessage,
        deleteUserMessages,
        getFormSubmissions,
        getFormSubmissionById,
        getNewItems,
        createInstallerVerification,
        createStrutCalcPdf,
        createDeadloadCalcPdf,
        getCalculatorSystems,
        calculatorUploadData,
        getUserLogins,
        getProjects,
        getProjectById,
        createProject,
        addProjectSubstitutions,
        createProjectPdf,
        getProducts,
        getProductProperties,
        createSubmittal,
        createSubmittalPdf
      }}
    >
      {children}
    </ApiContext.Provider>
  );
};

export default ApiContext;
