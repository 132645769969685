import React, { Suspense, Fragment, lazy } from 'react';
import { Switch, Redirect, Route } from 'react-router-dom';
import DashboardLayout from 'src/layouts/DashboardLayout';
import MainLayout from 'src/layouts/MainLayout';
import HomeView from 'src/views/home/HomeView';
import LoadingScreen from 'src/components/LoadingScreen';
import AuthGuard from 'src/components/AuthGuard';
import GuestGuard from 'src/components/GuestGuard';

type Routes = {
  exact?: boolean;
  path?: string | string[];
  guard?: any;
  layout?: any;
  component?: any;
  routes?: Routes;
}[];

export const renderRoutes = (routes: Routes = []): JSX.Element => (
  <Suspense fallback={<LoadingScreen />}>
    <Switch>
      {routes.map((route, i) => {
        const Guard = route.guard || Fragment;
        const Layout = route.layout || Fragment;
        const Component = route.component;

        return (
          <Route
            key={i}
            path={route.path}
            exact={route.exact}
            render={(props) => (
              <Guard>
                <Layout>
                  {route.routes ? (
                    renderRoutes(route.routes)
                  ) : (
                    <Component {...props} />
                  )}
                </Layout>
              </Guard>
            )}
          />
        );
      })}
    </Switch>
  </Suspense>
);

const routes: Routes = [
  {
    exact: true,
    path: '/downloads',
    component: lazy(() => import('src/views/downloadsPage'))
  },
  {
    exact: true,
    path: '/404',
    component: lazy(() => import('src/views/errors/NotFoundView'))
  },
  {
    exact: true,
    path: '/403',
    component: lazy(() => import('src/views/errors/ForbiddenView'))
  },
  {
    exact: true,
    path: '/offline',
    component: lazy(() => import('src/views/errors/ServerDownView'))
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/register',
    component: lazy(() => import('src/views/auth/RegisterView'))
  },
  {
    exact: true,
    path: '/register-unprotected',
    component: lazy(() => import('src/views/auth/RegisterView'))
  },
  {
    path: '/app',
    guard: AuthGuard,
    layout: DashboardLayout,
    routes: [
      {
        exact: true,
        path: '/app/account',
        component: lazy(() => import('src/views/account/AccountView'))
      },
      {
        exact: true,
        path: '/app/extra/charts/apex',
        component: lazy(() => import('src/views/extra/charts/ApexChartsView'))
      },
      {
        exact: true,
        path: '/app/extra/forms/formik',
        component: lazy(() => import('src/views/extra/forms/FormikView'))
      },
      {
        exact: true,
        path: '/app/extra/editors/draft-js',
        component: lazy(() => import('src/views/extra/editors/DraftEditorView'))
      },
      {
        exact: true,
        path: '/app/extra/editors/quill',
        component: lazy(() => import('src/views/extra/editors/QuillEditorView'))
      },
      {
        exact: true,
        path: [
          '/app/mail/label/:customLabel/:mailId?',
          '/app/mail/:systemLabel/:mailId?'
        ],
        component: lazy(() => import('src/views/mail/MailView'))
      },
      {
        exact: true,
        path: '/app/mail',
        component: () => <Redirect to="/app/mail/all" />
      },
      {
        exact: true,
        path: '/app/management/customers',
        component: lazy(() => import('src/views/customer/CustomerListView'))
      },
      {
        exact: true,
        path: '/app/management/customers/:customerId',
        component: lazy(() => import('src/views/customer/CustomerDetailsView'))
      },
      {
        exact: true,
        path: '/app/management/customers/:customerId/edit',
        component: lazy(() => import('src/views/customer/CustomerEditView'))
      },
      {
        exact: true,
        path: '/app/management/invoices',
        component: lazy(() => import('src/views/invoice/InvoiceListView'))
      },
      {
        exact: true,
        path: '/app/management/invoices/:invoiceId',
        component: lazy(() => import('src/views/invoice/InvoiceDetailsView'))
      },
      {
        exact: true,
        path: '/app/orders',
        component: lazy(() => import('src/views/order/OrderListView'))
      },
      {
        exact: true,
        path: '/app/orders/:orderId',
        component: lazy(() => import('src/views/order/OrderDetailsView'))
      },
      {
        exact: true,
        path: '/app/shipments',
        component: lazy(() => import('src/views/shipments/ShippingListView'))
      },
      {
        exact: true,
        path: '/app/ezsub/projects/:projectId',
        component: lazy(() => import('src/views/ezsub/projects/details'))
      },
      {
        exact: true,
        path: '/app/ezsub/:tab?/:id?',
        component: lazy(() => import('src/views/ezsub'))
      },
      {
        exact: true,
        path: '/app/info/tours',
        component: lazy(() => import('src/views/info/Tours'))
      },
      {
        exact: true,
        path: '/app/training/installation',
        component: lazy(() => import('src/views/training/Installation'))
      },

      {
        exact: true,
        path: '/app/calculator/deadload',
        component: lazy(() => import('src/views/calculator/DeadloadCalculator'))
      },
      {
        exact: true,
        path: '/app/calculator/windload',
        component: lazy(() => import('src/views/calculator/WindloadChart'))
      },
      {
        exact: true,
        path: '/app/calculator/structuralcalculator',
        component: lazy(
          () => import('src/views/calculator/StructuralCalculator')
        )
      },

      {
        exact: true,
        path: '/app/management',
        component: () => <Redirect to="/app/management/customers" />
      },
      {
        exact: true,
        path: '/app/resources/marketingmaterials',
        component: lazy(() => import('src/views/resources/MarketingMaterials'))
      },
      {
        exact: true,
        path: '/app/resources/productsamples',
        component: lazy(() => import('src/views/resources/ProductSamples'))
      },
      {
        exact: true,
        path: '/app/resources/InstallerVerification',
        component: lazy(
          () => import('src/views/resources/InstallerVerificationLetter')
        )
      },
      {
        path: '/app/admin',
        guard: AuthGuard,
        routes: [
          {
            exact: true,
            path: '/app/admin/forms',
            component: lazy(
              () => import('src/views/admin/Forms/FormSubmissionsListView')
            )
          },
          {
            exact: true,
            path: '/app/admin/forms/:formId',
            component: lazy(
              () => import('src/views/admin/Forms/FormSubmissionsDetailsView')
            )
          },
          {
            exact: true,
            path: '/app/admin/calculator',
            component: lazy(() => import('src/views/admin/Calculator'))
          },
          {
            exact: true,
            path: '/app/admin/users',
            component: lazy(() => import('src/views/admin/Users'))
          },
          {
            exact: true,
            path: '/app/admin/users/:userId',
            component: lazy(
              () => import('src/views/admin/Users/UserDetailsView')
            )
          },
          {
            exact: true,
            path: '/app/admin/customers',
            component: lazy(() => import('src/views/admin/Customers'))
          },
          {
            exact: true,
            path: '/app/admin/customers/:customerId',
            component: lazy(
              () => import('src/views/admin/Customers/CustomerDetailsView')
            )
          }
        ]
      },
      {
        exact: true,
        path: '/app',
        component: lazy(() => import('src/views/dashboard'))
      },
      {
        component: () => <Redirect to="/404" />
      }
    ]
  },
  {
    path: '*',
    layout: MainLayout,
    routes: [
      {
        exact: true,
        path: '/',
        component: HomeView
      },
      {
        exact: true,
        path: '/client*',
        component: () => <Redirect to="/" />
      },
      {
        exact: true,
        path: '/pricing',
        component: lazy(() => import('src/views/pricing/PricingView'))
      },
      {
        exact: true,
        path: '/files',
        component: lazy(() => import('src/views/files'))
      },
      {
        component: () => <Redirect to="/404" />
      }
    ]
  }
];

export default routes;
