import * as React from 'react';
import createSvgIcon from 'src/utils/createSvgIcon';

export const DiePunch = createSvgIcon(
  <g id="svg_10" stroke="null">
    <polygon
      points="7.479231357574463,6.571721315383911 0.31163454055786133,3.2019598484039307 7.554571628570557,0 14.722165584564209,3.366338014602661 "
      fill="#FFFFFF"
      id="svg_1"
      stroke="currentColor"
    />
    <polygon
      points="14.722165584564209,14.386554956436157 7.68812894821167,17.485777139663696 7.708675861358643,15.56117844581604 14.746137142181396,12.461955308914185 "
      fill="#FFFFFF"
      id="svg_2"
      stroke="currentColor"
    />
    <polygon
      points="0,14.218753099441528 7.020340442657471,17.526872873306274 7.044313907623291,15.602272272109985 0.020547151565551758,12.294151544570923 "
      fill="#FFFFFF"
      id="svg_3"
      stroke="null"
    />
    <polygon
      points="14.821479320526123,5.849140882492065 7.784017086029053,8.948360681533813 7.807989597320557,7.023762941360474 14.84202527999878,3.924541711807251 "
      fill="#FFFFFF"
      id="svg_4"
      stroke="currentColor"
    />
    <polygon
      points="0.09588789939880371,5.681338548660278 7.119652271270752,8.989455461502075 7.140199184417725,7.064856767654419 0.11643433570861816,3.7567365169525146 "
      fill="#FFFFFF"
      id="svg_5"
      stroke="currentColor"
    />
    <path
      d="m7.45183,9.58533l-1.13695,-0.53423l-0.02055,1.67461c0,0.13013 0.33561,0.48286 1.09928,0.48971c0.76368,0.01027 1.10613,-0.33561 1.10613,-0.46574l0.01712,-1.65406l-1.06504,0.48971l0.00001,0z"
      fill="#FFFFFF"
      id="svg_6"
      stroke="currentColor"
    />
    <path
      d="m2.1335,7.07171l-0.01713,1.61639c0,0.13013 0.33561,0.48286 1.09928,0.48971c0.76368,0.01027 1.10613,-0.33561 1.10613,-0.46574l-0.0137,-0.64724l-2.17459,-0.99312l0.00001,0z"
      fill="#FFFFFF"
      id="svg_7"
      stroke="currentColor"
    />
    <path
      d="m9.2326,9.43122l-0.01712,1.35955c-0.00685,0.53766 -0.70546,1.11298 -1.82529,1.09928c-1.12326,-0.0137 -1.81159,-0.60272 -1.80474,-1.14038l0.0137,-1.33215l-5.36628,2.37322l7.14705,3.35606l7.22239,-3.19511l-5.3697,-2.52047l-0.00001,0zm-6.00667,3.02731c-0.85957,-0.01027 -1.55475,-0.33561 -1.5479,-0.72943s0.70546,-0.70203 1.56502,-0.69176c0.85957,0.01027 1.55475,0.33561 1.5479,0.72943c-0.00342,0.3904 -0.70546,0.69861 -1.56502,0.69176zm4.12317,1.76365c-0.85956,-0.01027 -1.55475,-0.33561 -1.5479,-0.72943c0.00343,-0.39382 0.70546,-0.70203 1.56502,-0.69176c0.85956,0.01028 1.55475,0.33561 1.5479,0.72943c-0.00685,0.3904 -0.70546,0.70203 -1.56502,0.69176zm4.26357,-1.67118c-0.85956,-0.01027 -1.55475,-0.33561 -1.5479,-0.72943c0.00343,-0.39383 0.70546,-0.70203 1.56502,-0.69176c0.85957,0.01027 1.55475,0.33561 1.5479,0.72943c-0.00342,0.39382 -0.70546,0.70203 -1.56502,0.69176z"
      fill="#FFFFFF"
      id="svg_8"
      stroke="currentColor"
    />
    <path
      d="m12.82496,7.19156l-0.01712,1.61639c0,0.13013 -0.34588,0.47259 -1.10956,0.46574c-0.76368,-0.01027 -1.09586,-0.35958 -1.09586,-0.48971l0.0274,-0.64724l2.19514,-0.94518z"
      fill="#FFFFFF"
      id="svg_9"
      stroke="currentColor"
    />
  </g>,
  'DiePunch'
);
