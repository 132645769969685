import React, { createContext, useEffect } from 'react';
import type { FC, ReactNode } from 'react';
import createAuth0Client, { Auth0Client } from '@auth0/auth0-spa-js';
import type { User } from 'src/types/user';
import axios from 'axios';
import UseApi from 'src/hooks/useApi';
import SplashScreen from 'src/components/SplashScreen';
import { auth0Config } from 'src/config';
import { useHistory } from 'react-router-dom';
import useThunkReducer from 'src/hooks/useThunkReducer';
import { Customer, UsersApi, UserVm } from 'src/lib/api';
interface AuthState {
  isInitialised: boolean;
  isAuthenticated: boolean;
  user: User | null;
  accessToken: string;
  auth0Client: Auth0Client;
  loading: boolean;
}

export interface AuthContextValue extends AuthState {
  method: 'Auth0';
  loginWithRedirect: () => Promise<void>;
  register: () => Promise<void>;
  logout: () => void;
  setCustomer: (customer?: Customer | null) => void;
  updateUserDetails: (details: UserVm) => void;
}

interface AuthProviderProps {
  children: ReactNode;
}

type InitialiseAction = {
  type: 'INITIALISE';
  payload: {
    isAuthenticated: boolean;
    user: User | null;
    accessToken: string;
    auth0Client: Auth0Client;
  };
};

type LoginAction = {
  type: 'LOGIN';
  payload: {
    user: User;
  };
};

type LogoutAction = {
  type: 'LOGOUT';
};

type RegisterAction = {
  type: 'REGISTER';
};

type Loading = {
  type: 'LOADING';
};

type Loaded = {
  type: 'LOADED';
};

type SetCustomer = {
  type: 'SETCUSTOMER';
  payload: {
    customer: Customer | null;
  };
};

type UserDetails = {
  type: 'USERDETAILS';
  payload: UserVm;
};

type Action =
  | InitialiseAction
  | LoginAction
  | LogoutAction
  | RegisterAction
  | Loading
  | Loaded
  | SetCustomer
  | UserDetails;

const initialAuthState: AuthState = {
  isAuthenticated: false,
  isInitialised: false,
  user: null,
  accessToken: '',
  auth0Client: null,
  loading: true
};

const reducer = (state: AuthState, action: Action): AuthState => {
  switch (action.type) {
    case 'INITIALISE': {
      const {
        isAuthenticated,
        user,
        accessToken,
        auth0Client
      } = action.payload;
      return {
        ...state,
        isAuthenticated,
        isInitialised: true,
        user,
        accessToken,
        auth0Client
      };
    }
    case 'LOGIN': {
      const { user } = action.payload;

      return {
        ...state,
        isAuthenticated: true,
        user
      };
    }
    case 'LOGOUT': {
      return {
        ...state,
        isAuthenticated: false,
        user: null,
        accessToken: undefined,
        loading: false
      };
    }
    case 'LOADING': {
      return {
        ...state,
        loading: true
      };
    }
    case 'LOADED': {
      return {
        ...state,
        loading: false
      };
    }
    case 'SETCUSTOMER': {
      const { customer } = action.payload;
      if (customer) {
        state.user.details.customer = customer;
        //update local user
        window.localStorage.setItem('user', JSON.stringify(state.user.details));
      } else {
        const baseCustomer = window.localStorage.getItem('baseCustomer');
        if (baseCustomer) {
          state.user.details.customer = JSON.parse(baseCustomer);
          //update local user
          const localUserString = window.localStorage.getItem('user');
          const localUser = JSON.parse(localUserString);
          if (localUser) {
            localUser.customer = state.user.details.customer;
            window.localStorage.setItem('user', JSON.stringify(localUser));
          }
        } else {
          //if no customer is set, logout
          return {
            ...state,
            isAuthenticated: false,
            user: null,
            accessToken: undefined,
            loading: false
          };
        }
      }
      return { ...state };
    }
    case 'USERDETAILS': {
      state.user.details = action.payload;
      return {
        ...state
      };
    }
    default: {
      return { ...state };
    }
  }
};

const AuthContext = createContext<AuthContextValue>({
  ...initialAuthState,
  method: 'Auth0',
  loginWithRedirect: () => Promise.resolve(),
  register: () => Promise.resolve(),
  logout: () => {},
  setCustomer: () => {},
  updateUserDetails: () => {}
});

export const AuthProvider: FC<AuthProviderProps> = ({ children }) => {
  const { getUser } = UseApi();
  const [state, dispatch] = useThunkReducer(reducer, initialAuthState);

  const loginWithRedirect = async () => {
    await state.auth0Client.loginWithRedirect({
      appState: { targetUrl: window.location.pathname }
    });
  };

  const register = async () => {
    await state.auth0Client.loginWithRedirect({
      screen_hint: 'signup'
    });
  };

  const getUserDetails = () => {
    return async (dispatch, getState) => {
      //check local storage for customer
      var localUser;
      var localUserString = window.localStorage.getItem('user');
      localUser = JSON.parse(localUserString);
      if (!localUser) {
        const { user } = getState();
        let api = new UsersApi(undefined, window.location.origin, axios);
        const { data = {} } = await api.usersGetById(user.id);
        localUser = data;
        window.localStorage.setItem('user', JSON.stringify(localUser));
        window.localStorage.setItem(
          'baseCustomer',
          JSON.stringify(localUser?.customer)
        );
      }
      dispatch({
        type: 'USERDETAILS',
        payload: localUser
      });
    };
  };

  const updateUserDetails = (details: UserVm) => {
    console.log('updateUserDetails', details);
    //check local storage for customer
    var localUser;
    var localUserString = window.localStorage.getItem('user');
    localUser = JSON.parse(localUserString);
    if (localUser) {
      localUser = { ...localUser, ...details };
      window.localStorage.setItem('user', JSON.stringify(localUser));
    }
    dispatch({
      type: 'USERDETAILS',
      payload: localUser
    });
  };

  const logout = () => {
    dispatch({
      type: 'LOADING'
    });
    state.auth0Client.logout({
      returnTo: window.location.origin
    });
    window.localStorage.removeItem('user');
    window.localStorage.removeItem('baseCustomer');
    dispatch({
      type: 'LOGOUT'
    });
  };

  const setCustomer = (customer: Customer) => {
    dispatch({
      type: 'SETCUSTOMER',
      payload: {
        customer
      }
    });
  };

  let history = useHistory();
  useEffect(() => {
    const onRedirectCallback = (appState) => {
      history.push(
        appState && appState.targetUrl
          ? appState.targetUrl
          : window.location.pathname
      );
    };

    const initialise = async () => {
      dispatch({
        type: 'LOADING'
      });

      try {
        const auth0FromHook = await createAuth0Client({
          redirect_uri: window.location.origin,
          cacheLocation: 'localstorage',
          onRedirectCallback: onRedirectCallback,
          ...auth0Config
        });

        if (
          window.location.search.includes('code=') &&
          window.location.search.includes('state=')
        ) {
          const { appState } = await auth0FromHook.handleRedirectCallback();
          onRedirectCallback(appState);
        }

        const isAuthenticated = await auth0FromHook.isAuthenticated();
        if (isAuthenticated) {
          const user = await auth0FromHook.getUser();
          const token = await auth0FromHook.getTokenSilently();

          // Here you should extract the complete user profile to make it available in your entire app.
          // The auth state only provides basic information.
          dispatch({
            type: 'INITIALISE',
            payload: {
              isAuthenticated,
              user: {
                id: user.sub,
                avatar: user.picture,
                email: user.email,
                name: user.name,
                roles: user['https://tubeliteinc.com/roles'] || [''],
                details: {
                  customer: null
                }
              },
              accessToken: token,
              auth0Client: auth0FromHook
            }
          });
          //dispatch thunk
          dispatch(getUserDetails());
        } else {
          dispatch({
            type: 'INITIALISE',
            payload: {
              isAuthenticated,
              user: null,
              accessToken: undefined,
              customerNumber: null,
              auth0Client: auth0FromHook
            }
          });
        }
      } catch (err) {
        console.error(err);
        dispatch({
          type: 'INITIALISE',
          payload: {
            isAuthenticated: false,
            user: null,
            accessToken: undefined,
            customerNumber: null,
            auth0Client: null
          }
        });
      }

      dispatch({
        type: 'LOADED'
      });
    };

    initialise();
  }, [history, getUser, dispatch]);

  if (!state.isInitialised) {
    return <SplashScreen />;
  }

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'Auth0',
        loginWithRedirect,
        register,
        logout,
        setCustomer,
        updateUserDetails
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
