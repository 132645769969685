import * as React from 'react';
import createSvgIcon from 'src/utils/createSvgIcon';

export const LienWaiver = createSvgIcon(
  <g>
    <path d="m9.53516,6.12891l-5.99219,0l0,0.0039c-0.01172,0 -0.02344,-0.0039 -0.02734,-0.0039c-0.38282,0 -0.69922,0.30468 -0.69922,0.67968c0,0.3711 0.3164,0.67969 0.69922,0.67969c0.01171,0 0.01953,0 0.02734,-0.0039l0,0.0039l5.99219,0c0.38672,0 0.70312,-0.30859 0.70312,-0.67969c0,-0.3789 -0.3164,-0.67968 -0.70312,-0.67968zm0,0" />
    <path d="m9.53516,8.9375l-5.99219,0l0,0.00781c-0.01172,0 -0.02344,-0.00781 -0.02734,-0.00781c-0.38282,0 -0.69922,0.30859 -0.69922,0.67969c0,0.375 0.3164,0.68359 0.69922,0.68359c0.01171,0 0.01953,0 0.02734,-0.00781l0,0.00781l5.99219,0c0.38672,0 0.70312,-0.30859 0.70312,-0.68359c0,-0.3711 -0.3164,-0.67969 -0.70312,-0.67969zm0,0" />
    <path d="m9.53516,11.69531l-5.99219,0l0,0.00391c-0.01172,0 -0.02344,-0.00391 -0.02734,-0.00391c-0.38282,0 -0.69922,0.30469 -0.69922,0.67969c0,0.37109 0.3164,0.67969 0.69922,0.67969c0.01171,0 0.01953,0 0.02734,-0.00781l0,0.00781l5.99219,0c0.38672,0 0.70312,-0.3086 0.70312,-0.67969c0,-0.375 -0.3164,-0.67969 -0.70312,-0.67969zm0,0" />
    <path d="m9.53516,14.51172l-5.99219,0l0,0.00391c-0.01172,0 -0.02344,-0.00391 -0.02734,-0.00391c-0.38282,0 -0.69922,0.30469 -0.69922,0.67969c0,0.37109 0.3164,0.67968 0.69922,0.67968c0.01171,0 0.01953,0 0.02734,-0.00781l0,0.00781l5.99219,0c0.38672,0 0.70312,-0.30859 0.70312,-0.67968c0,-0.375 -0.3164,-0.67969 -0.70312,-0.67969zm0,0" />
    <path d="m9.53516,18.53906c0.38672,0 0.70312,-0.30859 0.70312,-0.67968c0,-0.375 -0.3164,-0.67969 -0.70312,-0.67969l-5.99219,0l0,0.0039c-0.01172,0 -0.02344,-0.0039 -0.02734,-0.0039c-0.38282,0 -0.69922,0.30469 -0.69922,0.67969c0,0.37109 0.3164,0.67968 0.69922,0.67968c0.01171,0 0.01953,0 0.02734,-0.00781l0,0.00781l5.99219,0zm0,0" />
    <path d="m18.64063,13.4375l-1.6836,0.59766l0,7.45703l-15.27734,0l0,-17.89844l8.26172,0c0.0625,-0.58203 0.3789,-1.17578 0.875,-1.63672l-9.86328,0c-0.52735,0 -0.95313,0.41406 -0.95313,0.92578l0,19.32032c0,0.51171 0.42578,0.92578 0.95313,0.92578l16.74609,0c0.52734,0 0.95312,-0.41407 0.95312,-0.92578c0,-0.03125 -0.0039,-0.0586 -0.01171,-0.08204l0,-8.68359zm0,0" />
    <path d="m16.11719,6.23047l0,-3.30078c0.59765,0.13672 1.20312,0.39062 1.82422,0.7539c0.17968,0.10547 0.35156,0.15625 0.53125,0.15625c0.54687,0 0.99218,-0.41406 0.99218,-0.94531c0,-0.41406 -0.25,-0.67187 -0.53515,-0.82812c-0.8125,-0.50391 -1.69922,-0.82813 -2.7461,-0.94532l0,-0.3789c0,-0.41406 -0.33593,-0.74219 -0.76171,-0.74219c-0.42579,0 -0.78125,0.32813 -0.78125,0.74219l0,0.36328c-2.21485,0.17187 -3.71875,1.44531 -3.71875,3.28516c0,1.9414 1.20312,2.88671 3.78906,3.54296l0,3.38672c-0.97266,-0.17187 -1.78906,-0.5664 -2.6211,-1.14843c-0.16015,-0.125 -0.375,-0.19141 -0.58593,-0.19141c-0.54688,0 -0.97266,0.41406 -0.97266,0.94531c0,0.36328 0.17969,0.64063 0.48047,0.82813c1.07812,0.74218 2.29687,1.20703 3.62891,1.33984l0,1.07031c0,0.41407 0.35546,0.73828 0.78125,0.73828c0.42578,0 0.76171,-0.32421 0.76171,-0.73828l0,-1.03515c2.2461,-0.20313 3.77344,-1.46094 3.77344,-3.35157c0,-1.84375 -1.15234,-2.89843 -3.83984,-3.54687zm-1.40235,-0.36328c-1.36328,-0.42969 -1.69921,-0.87891 -1.69921,-1.59766c0,-0.75781 0.57031,-1.33984 1.69921,-1.44922l0,3.04688zm1.40235,5.51953l0,-3.12891c1.36328,0.41407 1.75,0.87891 1.75,1.63672c0,0.82422 -0.6211,1.375 -1.75,1.49219zm0,0" />
  </g>,
  'LienWaiver'
);
