import React, { useRef, useMemo } from 'react';
import useSWR from 'swr';
import type { FC } from 'react';
import { IconButton, Tooltip, Badge } from '@material-ui/core';
import { Bell as BellIcon } from 'react-feather';

import UseApi from 'src/hooks/useApi';
import { NavLink } from 'react-router-dom';

const Notifications: FC = () => {
  const { getNewItems } = UseApi();
  const { data } = useSWR('newItems', getNewItems);

  const badgeCount = useMemo(() => {
    if (!data) return 0;

    return Object.values(data).reduce((acc: number, cur: Array<any>) => {
      return acc + cur.length;
    }, 0);
  }, [data]);

  const ref = useRef<any>(null);

  return (
    <>
      <Tooltip title="Notifications">
        <IconButton color="inherit" ref={ref} component={NavLink} to="/app/mail" >
          <Badge badgeContent={badgeCount} color="error">
            <BellIcon />
          </Badge>
        </IconButton>
      </Tooltip>
    </>
  );
};

export default Notifications;
