import * as React from 'react';
import createSvgIcon from 'src/utils/createSvgIcon';

export const MktMaterial = createSvgIcon(
  <g>
    <path
      stroke="currentColor"
      d="m8.51065,16.74201l-4.77339,0c-0.23572,0 -0.42935,-0.19363 -0.42935,-0.42935l0,-14.38331c0,-0.23572 0.19363,-0.42935 0.42935,-0.42935l4.77339,0c0.23572,0 0.42935,0.19363 0.42935,0.42935l0,14.38752c0,0.23572 -0.19363,0.42514 -0.42935,0.42514z"
      fill="none"
      strokeWidth="2.6484"
      strokeMiterlimit="10"
      id="svg_2"
    />
    <path
      stroke="currentColor"
      d="m3.31211,14.95304l-0.62298,-0.62298c-0.16837,-0.16837 -0.16837,-0.43777 0,-0.60614l0.65245,-0.65245"
      fill="none"
      strokeWidth="2.6484"
      strokeMiterlimit="10"
      id="svg_3"
    />
    <path
      stroke="currentColor"
      d="m7.52145,16.85145l-0.85029,0.85029c-0.16837,0.16837 -0.43777,0.16837 -0.60614,0l-0.73242,-0.73243"
      fill="none"
      strokeWidth="2.6484"
      strokeMiterlimit="10"
      id="svg_4"
    />
    <path
      stroke="currentColor"
      d="m9.03681,7.37623l3.82629,-3.82629c0.16837,-0.16837 0.43777,-0.16837 0.60614,0l3.37589,3.37589c0.16837,0.16837 0.16837,0.43777 0,0.60614l-7.78307,7.77886"
      fill="none"
      strokeWidth="2.6484"
      strokeMiterlimit="10"
      id="svg_5"
    />
    <path
      stroke="currentColor"
      d="m13.37243,11.19831l4.80286,0c0.23572,0 0.42935,0.19363 0.42935,0.42935l0,4.77339c0,0.23572 -0.19363,0.42935 -0.42935,0.42935l-13.11209,0"
      fill="none"
      strokeWidth="2.6484"
      strokeMiterlimit="10"
      id="svg_6"
    />
    <path
      stroke="currentColor"
      d="m6.30916,14.13643c0.59352,0.79136 -0.23993,2.41616 -1.86053,3.62845s-3.41377,1.55325 -4.00729,0.76189c-0.59352,-0.79135 0.23993,-2.41616 1.86053,-3.62845c0.10523,-0.07998 0.21047,-0.15575 0.31991,-0.22731"
      fill="none"
      strokeWidth="2.0462"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit="10"
      id="svg_7"
    />
  </g>,
  'MktMaterial'
);
