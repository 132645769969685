import * as React from 'react';
import createSvgIcon from 'src/utils/createSvgIcon';

export const ProductSample = createSvgIcon(
  <g stroke="null" id="svg_7">
    <rect
      stroke="currentColor"
      id="svg_1"
      strokeMiterlimit="10"
      strokeWidth="2.6"
      fill="none"
      height="20.21345"
      width="20.21345"
      y="0"
      x="0"
    />
    <rect
      stroke="currentColor"
      id="svg_2"
      strokeMiterlimit="10"
      strokeWidth="2.6"
      fill="none"
      height="20.21345"
      width="7.14786"
      y="0"
      x="0"
    />
    <rect
      stroke="currentColor"
      id="svg_3"
      strokeMiterlimit="10"
      strokeWidth="2.6"
      fill="none"
      height="7.01739"
      width="13.02831"
      y="13.19139"
      x="7.16184"
    />
    <line
      stroke="currentColor"
      id="svg_4"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeWidth="2.6"
      fill="none"
      y2="2.80043"
      x2="14.07206"
      y1="6.4163"
      x1="10.29777"
    />
    <line
      stroke="currentColor"
      id="svg_5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeWidth="2.6"
      fill="none"
      y2="3.10797"
      x2="17.14741"
      y1="9.57087"
      x1="10.45619"
    />
    <line
      stroke="currentColor"
      id="svg_6"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeWidth="2.6"
      fill="none"
      y2="6.57007"
      x2="17.38039"
      y1="10.18594"
      x1="13.61076"
    />
  </g>,
  'ProductSample'
);
