import * as React from 'react';
import createSvgIcon from 'src/utils/createSvgIcon';

export const ProjectInfo = createSvgIcon(
  <g stroke="null" id="svg_9">
    <g stroke="null" id="svg_1">
      <path
        stroke="null"
        id="svg_2"
        fill="currentColor"
        d="m8.6612,3.90041l-5.44432,0l0,0.00478c-0.00956,0 -0.01912,-0.00478 -0.0239,-0.00478c-0.34893,0 -0.63573,0.28679 -0.63573,0.63573c0,0.34893 0.28679,0.63573 0.63573,0.63573c0.00956,0 0.01912,0 0.0239,-0.00478l0,0.00478l5.44432,0c0.34893,0 0.63573,-0.2868 0.63573,-0.63573c-0.00478,-0.35371 -0.2868,-0.63573 -0.63573,-0.63573z"
      />
      <path
        stroke="null"
        id="svg_3"
        fill="currentColor"
        d="m8.6612,6.52936l-5.44432,0l0,0.00478c-0.00956,0 -0.01912,-0.00478 -0.0239,-0.00478c-0.34893,0 -0.63573,0.28679 -0.63573,0.63573c0,0.34893 0.28679,0.63573 0.63573,0.63573c0.00956,0 0.01912,0 0.0239,-0.00478l0,0.00478l5.44432,0c0.34893,0 0.63573,-0.28679 0.63573,-0.63573c-0.00478,-0.34893 -0.2868,-0.63573 -0.63573,-0.63573z"
      />
      <path
        stroke="null"
        id="svg_4"
        fill="currentColor"
        d="m8.6612,9.10573l-5.44432,0l0,0.00478c-0.00956,0 -0.01912,-0.00478 -0.0239,-0.00478c-0.34893,0 -0.63573,0.28679 -0.63573,0.63573c0,0.34893 0.28679,0.63573 0.63573,0.63573c0.00956,0 0.01912,0 0.0239,-0.00478l0,0.00478l5.44432,0c0.34893,0 0.63573,-0.2868 0.63573,-0.63573c-0.00478,-0.34893 -0.2868,-0.63573 -0.63573,-0.63573z"
      />
      <path
        stroke="null"
        id="svg_5"
        fill="currentColor"
        d="m8.6612,11.73946l-5.44432,0l0,0.00478c-0.00956,0 -0.01912,-0.00478 -0.0239,-0.00478c-0.34893,0 -0.63573,0.28679 -0.63573,0.63573c0,0.34893 0.28679,0.63573 0.63573,0.63573c0.00956,0 0.01912,0 0.0239,-0.00478l0,0.00478l5.44432,0c0.34893,0 0.63573,-0.28679 0.63573,-0.63573c-0.00478,-0.34893 -0.2868,-0.63573 -0.63573,-0.63573z"
      />
      <path
        stroke="null"
        id="svg_6"
        fill="currentColor"
        d="m8.6612,15.50603c0.34893,0 0.63573,-0.2868 0.63573,-0.63573c0,-0.34893 -0.2868,-0.63573 -0.63573,-0.63573l-5.44432,0l0,0.00478c-0.00956,0 -0.01912,-0.00478 -0.0239,-0.00478c-0.34893,0 -0.63573,0.28679 -0.63573,0.63573c0,0.34893 0.28679,0.63573 0.63573,0.63573c0.00956,0 0.01912,0 0.0239,-0.00478l0,0.00478l5.44432,0z"
      />
    </g>
    <path
      stroke="null"
      id="svg_7"
      fill="none"
      d="m10.63052,5.97489l0,-4.44532l-9.10573,0l0,16.73924l13.87608,0l0,-11.42876l-3.90519,0c-0.47799,0 -0.86516,-0.38717 -0.86516,-0.86516z"
    />
    <path
      stroke="null"
      id="svg_8"
      fill="currentColor"
      d="m16.93044,18.85674l0,-12.01669l0,-2.05536l0,0l-4.77035,-4.77035l0,-0.00956l-0.00956,0l-0.00478,-0.00478l-0.00478,0.00478l-1.51045,0l-9.76536,0c-0.47799,0 -0.86516,0.38717 -0.86516,0.86516l0,18.06806c0,0.47799 0.38717,0.86516 0.86516,0.86516l15.20967,0c0.47799,0 0.86516,-0.38717 0.86516,-0.86516c-0.00478,-0.02868 -0.00956,-0.05258 -0.00956,-0.08126zm-4.77035,-16.68188l3.13562,3.13562l-3.13562,0l0,-3.13562zm3.24078,16.09874l-13.87608,0l0,-16.74403l9.10573,0l0,4.44532c0,0.47799 0.38717,0.86516 0.86516,0.86516l3.90519,0l0,11.43355z"
    />
  </g>,
  'ProjectInfo'
);
