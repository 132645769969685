import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { AppThunk } from 'src/store';
import { TextValue } from 'src/types/TextValue';
import { ExtrusionDto, SystemDto, SystemsVm } from 'src/lib/api';
import { FieldValue } from 'src/types/FieldValue';
import {
  DeadLoadResult,
  DeadLoadValues,
  calculateDeadload
} from 'src/types/deadloadCalculator';

interface DeadloadState {
  glassList: TextValue[];
  horizontalTypes: string[];
  blockList: TextValue[];
  extrusions?: ExtrusionDto[];
  extrusionData: any;
  systemData: SystemDto[];
  systems: string[];
  values: DeadLoadValues;
  result: DeadLoadResult;
}

const glassList: TextValue[] = [
  {
    id: 0,
    text: '1" Dual Glazed Insulating (1/4"|1/2" Space |1/4")"',
    value: 6.58
  },
  {
    id: 1,
    text:
      '1-3/4" Triple Glazed Insulating (1/4"|1/2" Space|1/4"|1/2" Space|1/4")',
    value: 9.88
  },
  {
    id: 2,
    text: '1-5/16" Laminated Insulating (1/4"_0.06" PVB_1/4"|1/2" Space|1/4")',
    value: 10.21
  },
  {
    id: 3,
    text:
      '1-5/8" Double Laminated Insulating (1/4"_0.06" PVB_1/4"|1/2" Space|1/4"_0.06" PVB_1/4")',
    value: 13.83
  },
  { id: 4, text: '1/8" Monolithic', value: 1.65 },
  { id: 5, text: '3/16" Monolithic', value: 2.47 },
  { id: 6, text: '1/4" Monolithic', value: 3.29 },
  { id: 7, text: '3/8"  Monolithic', value: 4.94 },
  { id: 8, text: '1/2" Monolithic', value: 6.58 },
  { id: 9, text: '3/4" Monolithic', value: 9.88 },
  { id: 10, text: '1" Monolithic', value: 13.17 },
  { id: 11, text: 'Test Value 8', value: 8 }
];

const horizontalTypes = ['Horizontal', 'Sill', 'Door Header'];

const blockList = [
  { id: 1, text: '1/4 Points', value: 0.25 },
  { id: 2, text: '1/8 Points', value: 0.125 }
];

const defalutSystem = {
  name: '',
  id: 0,
  members: []
};

const defaultExtrusions = [{ id: 0, extrusionName: '---' }];

const initialState: DeadloadState = {
  glassList: glassList,
  horizontalTypes: horizontalTypes,
  blockList: blockList,
  extrusions: defaultExtrusions,
  systemData: null,
  extrusionData: {},
  systems: [],
  values: {
    selectedSystem: defalutSystem,
    selectedExtrusion: defaultExtrusions[0],
    horizontal: horizontalTypes[0],
    extrusion: '',
    width: 0,
    height: 0,
    glassWeight: glassList[0].value,
    glass: glassList[0].text,
    block: blockList[0].value
  },
  result: {
    maxDeflection: 0,
    isMaxDeflectionPass: null,
    maxStress: 0,
    isMaxStressPass: null,
    glassWeight: 0,
    settingBlockSpacing: 0,
    iyRequired: 0,
    syRequired: 0,
    endReactions: 0
  }
};

const slice = createSlice({
  name: 'deadload',
  initialState,
  reducers: {
    handleChange(state: DeadloadState, action: PayloadAction<FieldValue>) {
      if (action.payload.field === 'extrusion') {
        state.values.selectedExtrusion =
          state.extrusionData[action.payload.value];
      } else if (action.payload.field === 'glass') {
        state.values.glass = glassList[action.payload.value].text;
        state.values.glassWeight = glassList[action.payload.value].value;
      } else if (action.payload.field === 'horizontal') {
        state.values[action.payload.field] = action.payload.value;
        state.extrusions = filterExtrusions(
          action.payload.value,
          state.values.selectedSystem.members
        );
        if (state.extrusions && state.extrusions[0])
          state.values.selectedExtrusion = state.extrusions[0];
        else {
          state.values.selectedExtrusion = defaultExtrusions[0];
          state.extrusions = defaultExtrusions;
        }
      } else {
        state.values[action.payload.field] = Number(action.payload.value);
      }
      //If everything is filled, calculate
      if (hasInputs(state.values)) {
        state.result = calculateDeadload(state.values);
      } else
        state.result = {
          maxDeflection: 0,
          isMaxDeflectionPass: null,
          maxStress: 0,
          isMaxStressPass: null,
          glassWeight: 0,
          settingBlockSpacing: 0,
          iyRequired: 0,
          syRequired: 0,
          endReactions: 0
        };
    },
    getSystems(state: DeadloadState, action: PayloadAction<SystemsVm>): void {
      state.systemData = action.payload.systems;
      //setup extrusion dictionary
      state.extrusionData = {};
      if (action.payload.systems) {
        action.payload.systems.forEach((system) => {
          system.members.forEach((member) => {
            state.extrusionData[member.id] = member;
          });
        });

        state.systemData.unshift({ id: 0, name: '', members: [] });
        // state.steelData = action.payload.steels;
        state.systems = state.systemData?.map(({ name }) => name);
      }
    },
    selectSystem(state: DeadloadState, action: PayloadAction<string>) {
      const systemName = action.payload;
      if (systemName) {
        const foundSystem = state.systemData.find((s) => s.name === systemName);
        state.values.selectedSystem = foundSystem;

        state.extrusions = filterExtrusions(
          state.values.horizontal,
          state.values.selectedSystem.members
        );
        if (state.extrusions && state.extrusions[0]) {
          state.values.selectedExtrusion = state.extrusions[0];
        } else {
          state.values.selectedExtrusion = defaultExtrusions[0];
          state.extrusions = defaultExtrusions;
        }
        //If everything is filled, calculate
        if (hasInputs(state.values)) {
          state.result = calculateDeadload(state.values);
        } else
          state.result = {
            maxDeflection: 0,
            isMaxDeflectionPass: null,
            maxStress: 0,
            isMaxStressPass: null,
            glassWeight: 0,
            settingBlockSpacing: 0,
            iyRequired: 0,
            syRequired: 0,
            endReactions: 0
          };
      }
    }
  }
});

const hasInputs = (values: DeadLoadValues) => {
  return (
    values.selectedSystem.id > 0 &&
    values.selectedExtrusion.id > 0 &&
    values.width > 0 &&
    values.height > 0
  );
};

const filterExtrusions = (horizontal: string, members: ExtrusionDto[]) => {
  //filter extrusions on horizontal type
  switch (horizontal) {
    case 'Horizontal':
      return members.filter((e) => e.isHorizontal === true);
    case 'Sill':
      return members.filter((e) => e.isSill === true);
    case 'Door Header':
      return members.filter((e) => e.isDoorHead === true);
  }
};

export const reducer = slice.reducer;

export const getSystems = (
  getCalculatorSystems: () => Promise<SystemsVm>
): AppThunk => async (dispatch): Promise<void> => {
  // I don't like having to pass a function to call here
  // can we just pass the api call directly to the file instead
  // through an import?
  const systems = await getCalculatorSystems();
  dispatch(slice.actions.getSystems(systems));
};

export const selectSystem = (systemName: string): AppThunk => async (
  dispatch
) => {
  dispatch(slice.actions.selectSystem(systemName));
};

export const handleChange = (e: FieldValue): AppThunk => async (dispatch) => {
  dispatch(slice.actions.handleChange(e));
};

export default slice;
