import { ExtrusionDto, SystemDto } from 'src/lib/api';

export interface DeadLoadValues {
  selectedSystem: SystemDto;
  selectedExtrusion: ExtrusionDto;
  horizontal: string;
  extrusion: number | string;
  width: number;
  height: number;
  glassWeight: number;
  glass: string;
  block: number;
}
interface IDeadLoadResult {
  maxDeflection: number;
  isMaxDeflectionPass: boolean;
  maxStress: number;
  isMaxStressPass: boolean;
  glassWeight: number;
  settingBlockSpacing: number;
  iyRequired: number;
  syRequired: number;
  endReactions: number;
}

// Create a class that implements the interface
export class DeadLoadResult implements IDeadLoadResult {
  constructor(
    public maxDeflection: number = 0,
    public isMaxDeflectionPass: boolean = null,
    public maxStress: number = 0,
    public isMaxStressPass: boolean = null,
    public glassWeight: number = 0,
    public settingBlockSpacing: number = 0,
    public iyRequired: number = 0,
    public syRequired: number = 0,
    public endReactions: number = 0
  ) {}
}

const bRupture: number = 1.95;
const bFlexure: number = 1.65;
const Fb: number = 15200; //psi
const Ftu: number = 30000; //psi
const Fcy: number = 25000; //psi
const Fty: number = 25000; //psi
const Cb: number = 1.0;
const kt: number = 1;
const Eal: number = 10100000; //psi
const C1: number = 0.05;
const C2: number = 0.5;
const go: number = 0.0; //in
const k: number = 1.0; //psi
const Bite: number = 0.5; //in

export const calculateDeadload = (values: DeadLoadValues): DeadLoadResult => {
  let result = {
    maxDeflection: 0,
    isMaxDeflectionPass: null,
    maxStress: 0,
    isMaxStressPass: null,
    glassWeight: 0,
    settingBlockSpacing: 0,
    iyRequired: 0,
    syRequired: 0,
    endReactions: 0
  };

  //Elevation Factors
  let DLOvert = values.height;
  let DLOhor = values.width;
  let Wglass = values.glassWeight;
  let SB = values.block;

  //Calculations
  //Deflection due to the weight of the glass
  let Hglass = DLOhor + 2 * Bite;
  let Vglass = DLOvert + 2 * Bite;
  let Areaglass = (Hglass * Vglass) / 144; //sqft
  let WG = Areaglass * Wglass; //lbf  (pounds force)
  //Set total glass weight
  result.glassWeight = WG;

  let Pglass = WG * 0.5;
  //Set End Reactions
  result.endReactions = Pglass;

  let Lb = DLOhor;
  let a = SB * Lb;
  //Set Setting block spacing
  result.settingBlockSpacing = a;

  let Mglass = Pglass * a; //lbf in
  let DefGlass =
    ((Pglass * a) / (24 * Eal * values.selectedExtrusion.iy)) *
    (3 * Math.pow(Lb, 2) - 4 * Math.pow(a, 2));

  //Deflection due to weight of the horizontal
  let Pext = 0.0975 * values.selectedExtrusion.area * Lb; //lbf
  let Mext = (Pext * Lb) / 8; //lbf in
  let Defext =
    (5 * Pext * Math.pow(Lb, 3)) / (384 * Eal * values.selectedExtrusion.iy);

  //Total Deflection
  let Deftot = Defext + DefGlass;
  //Set Max deflection
  result.maxDeflection = Deftot;

  //Check Deflection Criteria

  //If Horizontal type == Door Header; default
  let DefAll = 3 / 32; //in
  //else
  if (values.horizontal !== 'Door Header') {
    let s1 = Lb / 360; //in
    let s2 = 0.125; //in
    DefAll = Math.min(s1, s2);
  }

  //Set isMaxDeflectionPass
  result.isMaxDeflectionPass = Deftot < DefAll;

  //Maximum Stress in Extrusion
  let Mtot = Mext + Mglass; //in lbf
  let Otot = Mtot / values.selectedExtrusion.sy; //psi
  //Set Max Stress
  result.maxStress = Otot;

  //Flexural Yield Strength Limit
  let m1 = values.selectedExtrusion.zy * Fcy; //in lbf
  let m2 = 1.5 * values.selectedExtrusion.sy * Fty; //in lbf
  let m3 = 1.5 * values.selectedExtrusion.sy * Fcy; //in lbf
  let Myield = Math.min(m1, m2, m3) / bFlexure; //in lbf

  //Flexural Rupture Strength Limit
  let Mrupture = (values.selectedExtrusion.zy * Ftu) / kt / bRupture; //in lbf
  let U = C1 * go + (C2 * values.selectedExtrusion.b) / 2; //in
  let Me =
    ((Cb * Math.pow(Math.PI, 2) * Eal * values.selectedExtrusion.ix) /
      Math.pow(Lb, 2)) *
    (U +
      Math.sqrt(
        Math.pow(U, 2) +
          (0.038 * values.selectedExtrusion.j * Math.pow(Lb, 2)) /
            values.selectedExtrusion.ix +
          values.selectedExtrusion.cw / values.selectedExtrusion.ix
      )); //in lbf
  let λ = Math.PI * Math.sqrt((Eal * values.selectedExtrusion.sy) / Me);

  //Member Buckling
  let Bc = Fcy * (1 + Math.sqrt(Fcy / (2250 * k))); //psi
  let Dc = (Bc / 10) * Math.sqrt(Bc / Eal); //psi
  let Cc = 0.41 * (Bc / Dc);

  //Lateral Torsional Buckling
  let Mc1 =
    (Me * (1 - λ / Cc) +
      (Math.pow(Math.PI, 2) * Eal * λ * values.selectedExtrusion.sy) /
        Math.pow(Cc, 3)) /
    bFlexure; //in lbf

  let Mc2 =
    (Math.pow(Math.PI, 2) * Eal * values.selectedExtrusion.sy) /
    Math.pow(λ, 2) /
    bFlexure; //in lbf

  let Mb = λ < Cc ? Mc1 : Mc2;
  let Mcritical = Math.min(Mb, Myield, Mrupture); //in lbf
  let StressCritical = Mcritical / values.selectedExtrusion.sy; // psi

  //Maximum Allowabe Stress, All Sources
  let oMaxAllow = Math.min(Fb, StressCritical); //psi

  //Set isMaxStressPass
  result.isMaxStressPass = Otot < oMaxAllow;

  //Sy Required
  result.syRequired = Mtot / oMaxAllow; // in3

  //Iy Required
  result.iyRequired =
    (Pglass * a * (3 * Math.pow(Lb, 2) - 4 * Math.pow(a, 2)) +
      (5 / 16) * Pext * Math.pow(Lb, 3)) /
    (24 * DefAll * Eal); //in4

  //DEBUG - log all variables
  console.log('Deadload Calculations');
  console.log({
    DLOvert,
    DLOhor,
    Wglass,
    SB,
    Hglass,
    Vglass,
    Areaglass,
    WG,
    Pglass,
    Lb,
    a,
    Mglass,
    DefGlass,
    Pext,
    Mext,
    Defext,
    Deftot,
    DefAll,
    Mtot,
    Otot,
    m1,
    m2,
    m3,
    Myield,
    Mrupture,
    U,
    Me,
    λ,
    Bc,
    Dc,
    Cc,
    Mc1,
    Mc2,
    Mb,
    Mcritical,
    StressCritical,
    oMaxAllow
  });
  console.log('Deadload Results');
  console.log(result);

  return result;
};
